<template>
  <div class="cart_div">
    <div class="collapse">
      <div class="collapse-title" @click="collapse">
        <span>IN YOUR CART <i :class="collapseIcon"></i></span>
        <span>${{ total.toFixed(2) }}</span>
      </div>
      <div class="collapse-body">
        <div class="cart_row header-collapse">
          <div>
            <p class="text_title">IN YOUR CART</p>
          </div>
        </div>
        <div
          class="cart_row mh-50"
          v-for="product in cart"
          :key="product.uuid"
        >
          <div>
            <product-view :product="product"></product-view>
          </div>
        </div>
        <div class="cart_row t_initial">
          <div class="content">
            <p class="cart_subtotal">SubTotal</p>
            <p>${{ subtotal.toFixed(2) }}</p>
          </div>
          <div class="content">
            <p class="cart_taxes">Taxes</p>
            <p>${{ tax.toFixed(2) }}</p>
          </div>
          <div>
            <p class="cart_free">Free Shipping</p>
          </div>
          <div>
            <p class="cart_msg">*6 business days</p>
          </div>
        </div>
        <div class="cart_row t_initial">
          <div class="content">
            <p class="cart_total_label">Total</p>
            <p class="cart_total">${{ total.toFixed(2) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductView from "@/components/Cart/ProductView.vue";

export default {
  props: ["cart", "taxinfo"],
  components: {
    ProductView,
  },
  data() {
    return {
      collapseIcon: "el-icon-arrow-down",
    };
  },
  computed: {
    subtotal() {
      const { cart } = this;
      if (!Array.isArray(cart)) {
        return;
      }

      const subtotal = cart.reduce((total, obj) => {
        const price = +obj?.productInfo?.price ?? 0;
        const productSubtotal = +obj.quantity * price;
        total += productSubtotal;
        return Number.isNaN(total) ? 0.0 : total;
      }, 0);

      return subtotal;
    },
    tax() {
      const { taxinfo, subtotal } = this;

      if (!taxinfo) {
        return 0;
      }

      return taxinfo?.flat_rate * subtotal ?? 0;
    },
    total() {
      const { subtotal, tax } = this;
      const total = subtotal + tax;
      return total;
    },
  },
  methods: {
    collapse() {
      const collapse = document.querySelector(".collapse-body");
      collapse.classList.toggle("open");
      if (collapse.classList.contains("open") === true) {
        this.collapseIcon = "el-icon-arrow-up";
      } else {
        this.collapseIcon = "el-icon-arrow-down";
      }
    },
  },
  mounted() {
  },
  beforeUnmount() {
  },
};
</script>

<style lang="scss" scoped>

.cart_subtotal{
  font-family: "JJRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  color: #293644;
  @media only screen and (max-width: 768px) {
    font-size: 18px !important;
  }
}
.cart_taxes{
  font-family: "JJRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.03em;
  color: #293644;
  @media only screen and (max-width: 768px) {
    font-size: 18px !important;
  }
}
.cart_div {
  .collapse {
    width: 360px;
    @media only screen and (min-width: 1250px) {
      width: 470px;
    }
    @media only screen and (max-width: 768px) {
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
    }

    .collapse-title {

      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      text-transform: uppercase;
      color: #154734;

      display: none;
      padding: 20px 0;

      span:first-child {
        font-family: "JJRg-Regular";
      }

      span:last-child {
        font-family: "JJLt-Regular";
      }

      i {
        font-weight: bolder;
      }

      @media only screen and (max-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .collapse-body {
      display: block;
      @media only screen and (max-width: 768px) {
        display: none;
        transition: font-size 0.2s 0.3s linear, padding 0.2s 0.3s linear,
        opacity 0.2s linear;
      }
    }

    .open {
      display: block;
      transition: font-size 0.2s linear, padding 0.2s linear,
      opacity 0.2s 0.3s linear;
    }
  }

  .cart_row {
    border-bottom: 0.8px solid #d1d1d1;

    &:last-child {
      border-bottom: 0;
    }

    &.header-collapse {
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    padding: 22px 32px;
    @media only screen and (min-width: 1250px) {
      padding: 22px 30px;
    }
    @media only screen and (max-width: 768px) {
      padding: 22px 0;
    }

    &.mh-50 {
      min-height: 460px;
    }

    &.t_initial {
      text-align: initial;

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    p {
      font-family: "JJLt-Regular";
      margin: 10px 0;
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      letter-spacing: 0.03em;
      color: #293644;

      &.cart_total {
        font-family: "JJRegular";
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 48px;
        text-align: right;
        color: #154734;
        @media only screen and (max-width: 768px) {
          font-size: 28px;
        }
      }

      &.cart_total_label {
        font-family: "JJRegular";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.03em;
        color: #293644;
        @media only screen and (max-width: 768px) {
          font-size: 18px !important;
        }
      }

      &.cart_free {
        font-family: "JJLt-Regular";
        font-style: normal;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        letter-spacing: 0.03em;
        color: #293644;
        @media only screen and (max-width: 768px) {
          font-size: 18px;
        }
      }

      &.cart_free_msg{
        font-family: 'JJLt-Regular';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.03em;
        color: #000000;
        @media only screen and (max-width: 768px) {
          font-size: 18px;
        }
      }
    }

    .text_title {
      font-family: "JJLt-Regular";
      font-style: normal;
      font-weight: 700;
      font-size: 21.4274px;
      line-height: 140%;
      text-align: initial;
      text-transform: uppercase;
      color: #154734;
      margin: 0;
    }
  }

  .el-collapse {
    .divlapse-item__header {
      background-color: transparent !important;
    }
  }
  .cart_msg{
    font-family: "JJRegular";
    font-style: normal !important;
    font-weight: 300!important;
    font-size: 14px!important;
    line-height: 22px!important;
    letter-spacing: 0.03em!important;
    color: #000000!important;
  }
}
</style>

