<template>
    <div id="app">
        <count-down></count-down>
        <router-view/>
    </div>
</template>

<script>
import ApiSSO from '@/lib/ApiBase';
import jwtDecode from 'jwt-decode';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { mapGetters, mapMutations } from "vuex";
import axios from "axios";
import CountDown from "@/components/CountDown.vue";

export default {
    name: 'App',
    components: {
        CountDown
    },
    methods: {
        ...mapMutations(["setFingerprint"]),
        ...mapGetters(["getFingerprint"]),

        async validateToken() {
            const now = Date.now() / 1000;
            const exp = +this.user.exp;
            if (now + 30 >= exp && !this.flag) {
                this.flag = true;
                // refresh token
                console.log('token expired, refresh token', new Date());
                try {
                    this.refreshTokenOld = this.refreshToken;
                    this.setRefreshToken(null);
                    const r = await ApiSSO.post('/refreshToken', {
                        applicationId: this.user.applicationId,
                        refreshToken: this.refreshTokenOld,
                    });
                    if (r.success) {
                        const { accessToken, refreshToken } = r.data;
                        const user = jwtDecode(accessToken).data;

                        this.setUser(user);
                        this.setToken(accessToken);
                        this.setRefreshToken(refreshToken);
                        return;
                    }

                    this.setUser(null);
                    this.setToken(null);
                    this.setRefreshToken(null);
                    this.$router.push({
                        path: '/login',
                        query: { redirect: this.$route.fullPath },
                    });
                } catch (e) {
                    console.error(e);
                    this.setUser(null);
                    this.setToken(null);
                    this.setRefreshToken(null);
                    this.$router.push({
                        path: '/login',
                        query: { redirect: this.$route.fullPath },
                    });
                } finally {
                    this.flag = false;
                }
            } else {
                // console.log('no refresh token', new Date());
            }
        },
        async addInfo() {
            try {
                const payload = {
                    "fingerprint": this.getFingerprint(),
                    "session_id": sessionStorage.getItem("web_session_id"),
                    "url": window.location.href
                }

                const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
                const url = `${VUE_APP_API_BASE}/info`;
                const config = {
                    headers: {
                        "Authorization": `Bearer ${VUE_APP_API_KEY}`,
                    }
                };


                await axios.post(url, payload, config);

            } catch (error) {
                console.error(error);
            }
        },
        addSessionsParams() {

            const fingerprint = getBrowserFingerprint();

            const sesionFinger = this.getFingerprint();
            if (!sesionFinger) {
                this.setFingerprint(fingerprint);
            }

            const web_session_id = this.$uuid.v4();
            const session_web_session_id = sessionStorage.getItem("web_session_id");
            if (!session_web_session_id) {
                sessionStorage.setItem("web_session_id", web_session_id);
            }
        },
    },

    async mounted() {
        await this.addSessionsParams();
        await this.addInfo();
        // await Util.loadScript('https://js.stripe.com/v3', 'stripe-v3');
        this.$router.push({
            path: '/init',
        });
        // eslint-disable-next-line no-unused-vars
        const observer = new MutationObserver((mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList' || mutation.type === 'attributes') {
                    let element = document.querySelector('.v-modal');
                    if (element) {
                        element.parentNode.removeChild(element);
                    }

                    let dialogWrappers = document.querySelectorAll('.el-dialog__wrapper');
                    let isAnyDialogVisible = Array.from(dialogWrappers).some(wrapper => wrapper.style.display !== 'none');

                    if (isAnyDialogVisible) {
                        document.body.style.overflow = 'hidden';
                        document.documentElement.style.overflow = 'hidden';
                    } else {
                        document.body.style.overflow = 'auto';
                        document.documentElement.style.overflow = 'auto';
                    }
                }
            }
        });

        observer.observe(document, { childList: true, subtree: true, attributes: true });
    },
}
</script>

<style>
@import '@/styles/style.scss';

#app {
    background-color: white;
    /* padding-bottom: 50px; */
}
</style>
