import Vue from 'vue';
import VueRouter from 'vue-router';
// import store from '@/store';

// import SSOApi from 'sso-api-integration';


Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    // component: () => import(/* webpackChunkName: "Home" */ '../views/PaymentCardDetails.vue'),
    component: () => import(/* webpackChunkName: "Home" */ '../views/CreateYourJameson.vue'),
    // component: () => import(/* webpackChunkName: "Home" */ '../views/Welcome.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/init',
    name: 'Init',
    // component: () => import(/* webpackChunkName: "Home" */ '../views/Home.vue'),
    // component: () => import(/* webpackChunkName: "Home" */ '../views/PaymentCardDetails.vue'),
    component: () => import(/* webpackChunkName: "Home" */ '../views/CreateYourJameson.vue'),
    // component: () => import(/* webpackChunkName: "Home" */ '../views/Welcome.vue'),
    meta: { requiresAuth: false },
  },

  {
    path: '/select-product',
    name: 'SelectProduct',
    component: () => import(/* webpackChunkName: "Home" */ '../views/SelectProduct.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/order-form',
    name: 'OrderForm',
    component: () => import(/* webpackChunkName: "Home" */ '../views/PaymentCardDetails.vue'),
    // component: () => import(/* webpackChunkName: "Home" */ '../components/CreateYourJameson.vue'),
    // component: () => import(/* webpackChunkName: "Home" */ '../views/Welcome.vue'),
    meta: { requiresAuth: false },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../components/AuthView.vue'),
  },
  {
    path: '/token',
    name: 'Token',
    component: () => import(/* webpackChunkName: "Token" */ '../components/AuthToken.vue'),
    props: (route) => ({ params: { ...route.query, ...route.params } }),
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "NotFound" */ '../components/NotFound.vue'),
  },

];

const router = new VueRouter({
  mode: 'abstract',
  // mode: 'history',
  base: '/',
  routes,
});

// router.beforeEach((to, from, next) => {

//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     const session = store.getters.isLoggedIn;

//     if (!session || session === null) {

//       SSOApi.configure({
//         appId: '054267cf-a8e5-4f07-854c-1590550ffc10', 
//         apiKey: 'asddfsdqw3242345gbv423'
//       });

//       SSOApi.redirectToLoginURL({
//         state: window.btoa(from.fullPath),
//         redirecturl: 'http://localhost:8081/token'
//       });

//     } else {
//       next();
//     }
//   } else {
//     next();
//   }
// });

export default router;
