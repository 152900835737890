
const _sign = require('jwt-encode');
const { VUE_APP_CRYPTO_KEY } = process.env;

const Util = {
  makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  },
  async loadScript(url, id = null, randomVersionDivider = null) {
    return new Promise((resolve, reject) => {
      if (randomVersionDivider != null) {
        const randomString = `${randomVersionDivider}v=${this.makeid(5)}`;
        url += randomString;
      }
      try {
        const config = {
          type: 'text/javascript',
          src: url,
          onload: () => resolve(),
        };
        if (id != null) {
          config.id = id;
        }
        document.body.appendChild(Object.assign(document.createElement('script'), config));
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  },
  scrollTo() {
    let div = document.getElementById('payment-form');
    let divTop = div.offsetTop;
    window.scrollTo({
      top: divTop - 100,
      behavior: "smooth"
    });
  },
  scrollToSelectProduct() {
    let div = document.getElementById('select-product');
    let divTop = div.offsetTop;
    window.scrollTo({
      top: divTop - 100,
      behavior: "smooth"
    });
  },
  preloadImages(imageArray) {
    let images = [];
    for (let i = 0; i < imageArray.length; i++) {
      images[i] = new Image();
      images[i].src = imageArray[i].src;
    }
  },
  signToken(payload, secretKey = VUE_APP_CRYPTO_KEY) {
    return _sign(payload, secretKey);
  },
};

export default Util;
