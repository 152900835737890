<template>
    <!-- <section class="count-down" v-if="stateCountDown"> -->
    <section v-if="false" class="count-down">
        <video autoplay muted playsinline
               id="countown-video-background-desktop"
        >
            <source
                class="desktop"
                :src="`${baseURL}/assets/countDown/desktop.mp4`"
                type="video/mp4"

            />
            <source
                class="mobile"
                :src="`${baseURL}/assets/countDown/mobile.mp4`"
                type="video/mp4"
            />
        </video>
        <div class="container-countdown">
            <div class="title">
                <h1>Ring in <br> St. Patrick’s Eve in NYC</h1>
                <template v-if="isNY">
                    <p class="sub-title-nyc">
                        Bring your friends and meet us in Times Square to celebrate St. Patrick’s Eve.
                    </p>
                </template>
                <template v-else>
                    <p class="sub-title">Can’t make it in person?</p>
                    <p class="sub-title-text">
                        Visit JamesonSPE.com to watch our Times Square “rock drop” moment live at 8:00 p.m. ET on Saturday, March 16  (an Irish midnight, aka midnight in Ireland)
                    </p>
                </template>
            </div>
            <div class="count">
                <div class="do-splitflap" ref="splitflap"></div>
                <div class="counter-labels">
                    <span>Days</span>
                    <span>Hours</span>
                    <span>Minutes</span>
                </div>
            </div>
            <div class="count-down-footer">
                <template v-if="isNY">
                    <p class="sub-text-counter-nyc">
                        DJs, entertainment, and giveaways. Plus a first-of-its-kind “rock drop” moment
                        at 8:00 p.m. ET (Jameson’s version of the ball drop)
                        <br>
                        <br>
                        <br>
                        You’re invited! March 16, 2024 <br> 5:00 p.m. - 10:00 p.m. ET
                    </p>

                    <!-- <p class="date-txt">You’re invited! March 16, 2024 From 6:00 p.m. to  10:00 p.m. ET</p> -->

                    <button type="button" @click="goToRSVP" class="RSVP-btn">
                        Pre-register here
                    </button>
                </template>

                <template v-else>
                    <!-- <p class="date-txt2">Saturday, March 16th</p> -->
                </template>
            </div>
        </div>

        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :visible.sync="showModal"
            class="modal-RSVP"
        >
            <div class="bg-image">
                <img class="desktop" :src="`${baseURL}/assets/init/bg_modal.png`" alt="bg_modal"/>
                <img class="mobile" :src="`${baseURL}/assets/init/bg_modal_mobile.png`" alt="bg_modal_mobile"/>
                <span class="btn-close" @click="showModal = false"
                ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                >
            <path d="M2 2L26 26M26 2L2 26" stroke="white" stroke-width="3"/>
          </svg>
        </span>
            </div>

            <div class="container">
                <h1 class="title">Sorry, the doors <br> are closed</h1>
                <p class="description">
                    Our St. Patrick’s Eve party is at capacity, so we can’t take any more RSVPs. <br> Visit
                    JamesonSPE.com to watch our Times Square ¨rock drop¨ moment at 8:00 p.m. ET on March 16 (an Irish
                    midnight, aka midnight in Ireland)
                </p>
            </div>
        </el-dialog>
    </section>
    <!-- <div v-else></div> -->
</template>
<script>
import axios from "axios";
// import "@/assets/libs/jQuery/jquery.min.js";
// import "@/assets/libs/splitFlap/jquery.splitflap.js";

export default {
    name: "CountDown",
    data() {
        return {
            isNY: false,
            activeRSVP: false,
            showModal: false,
            baseURL: process.env.VUE_APP_URL_BASE ?? "",
            conf: {
                image: "",
                charsMap: " 0123456789:",
                textInit: "00:00:00",
                text: "00:00:00",
            },
            targetDate: new Date(Date.UTC(2024, 2, 17, 0, 0, 0)),
            // targetDate: new Date("2024-03-16T20:00:00"),
            stateCountDown: true,
        };
    },
    methods: {
        initScripts() {
            const scriptUrls = [
                "https://code.jquery.com/jquery-3.6.0.min.js",
                "https://archeio.nyc3.cdn.digitaloceanspaces.com/websites/jameson-touchdown-2023/jquery.splitflap.js",
            ];

            const executeJSFromSrcArray = (scriptSrcArray, callback) => {
                let scriptsLoaded = 0;

                function onScriptLoad() {
                    scriptsLoaded++;
                    if (scriptsLoaded === scriptSrcArray.length) {
                        if (typeof callback === "function") {
                            callback();
                        }
                    }
                }

                for (let i = 0; i < scriptSrcArray.length; i++) {
                    const script = document.createElement("script");
                    script.src = scriptSrcArray[i];
                    script.async = false;
                    script.onload = onScriptLoad;
                    script.onerror = onScriptLoad;
                    document.head.appendChild(script);
                }
            }


            return new Promise((resolve, reject) => {


                executeJSFromSrcArray(scriptUrls, () => {
                    try {
                        resolve();
                    } catch (error) {
                        console.error(error);
                        reject(error);
                    }
                });
            });
        },
        async getIPAddress() {
            try {
                const response = await fetch("https://api64.ipify.org?format=json");
                const data = await response.json();
                return data.ip;
            } catch (error) {
                console.error("ERROR get IP:", error);
                return null;
            }
        },
        async getDataIpClient() {
            const ip = await this.getIPAddress();
            try {
                const { data } = await axios.get(
                    `https://api.geolocation.debrain.cloud/public/location/${ip}`
                );
                const { state_code, country_code } = data;

                if (state_code === "NY" || country_code === "CO") {
                    this.isNY = true;
                }
            } catch (error) {
                console.log("🚀 ------------------------------------🚀");
                console.log("🚀 ~ getDataIpClient ~ error:", error);
                console.log("🚀 ------------------------------------🚀");
                this.isNY = false;
            }
        },
        goToRSVP() {
            if (!this.activeRSVP) {
                this.showModal = true;
            } else {
                // window.open(
                //   "https://plant.hellosandia.com/test.php?slug=jameson-spe",
                //   "_blank"
                // );
                window.open(
                    "https://www.jamesonwhiskey.com/en-us/rsvp-jameson-spe-nyc",
                    "_blank"
                );
            }
        },
        async getAvailableEntriesRSVP() {
            try {
                const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
                const url = `${VUE_APP_API_BASE}/rsvp-event`;

                const config = {
                    headers: {
                        Authorization: `Bearer ${VUE_APP_API_KEY}`,
                    },
                    withCredentials: true,
                };

                const { data: response } = await axios.get(url, config);
                const { data, success } = response;
                const { available_entries } = data;

                if (!success) {
                    console.error("ERROR from sandia");
                    this.activeRSVP = false;
                }

                this.activeRSVP = available_entries > 0;
            } catch (error) {
                console.log("🚀 --------------------------------------------🚀");
                console.log("🚀 ~ getAvailableEntriesRSVP ~ error:", error);
                console.log("🚀 --------------------------------------------🚀");
                this.activeRSVP = false;
            }
        },
        initSplitFlap() {
            window.$(".do-splitflap").splitFlap(this.conf);
        },
        calculateTimeDifference(targetDate) {
            const now = new Date();
            const diff = targetDate.getTime() - now.getTime();

            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor(
                (diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
            );
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

            return { days, hours, minutes };
        },
        setCountdown() {
            const { days, hours, minutes } = this.calculateTimeDifference(
                this.targetDate
            );
            const countdownString = `${days.toString().padStart(2, "0")}:${hours
                .toString()
                .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
            this.conf.text = countdownString;
            
            this.initSplitFlap();
        },
        countdownPause() {

            const video = document.getElementById('countown-video-background-desktop');
            video.addEventListener("ended", function () {
                setTimeout(function () {
                    video.play(); // Resume video playback
                }, 2000); // Pause for 2 seconds (2000 milliseconds)
            })
        },
        clearCountdownListener() {
            const video = document.getElementById('countown-video-background-desktop');
            video.removeEventListener('ended');
        },
        limitCountDown() {
            const now = new Date();
            const today = Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
            // const lastWeek = Date.UTC(2024, 2, 16, 22, 0, 0);
            const { targetDate } = this;
            this.stateCountDown = today <= targetDate;
        },
        async loadJquery() {
            // Create a script element
            var script = document.createElement('script');
    
            // Set the source of the script to the jQuery CDN
            script.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    
            // Append the script element to the document's head
            await document.head.appendChild(script);
        },  
        async loadSplitFlap() {
            // Create a script element
            var script = document.createElement('script');
    
            // Set the source of the script to the jQuery CDN
            script.src = 'https://archeio.nyc3.cdn.digitaloceanspaces.com/websites/jameson-touchdown-2023/jquery.splitflap.js';
    
            // Append the script element to the document's head
            await document.head.appendChild(script);
        },  
    },
    async mounted() {
        await this.initScripts();

        // this.conf.image = `${this.baseURL}/assets/countDown/Countdown.png`;
        // this.conf.image = `${this.baseURL}/assets/countDown/numeros_1.png`;
        this.conf.image = `${this.baseURL}/assets/countDown/numeros_2.png`;
        this.setCountdown();
        await this.getAvailableEntriesRSVP();
        await this.getDataIpClient();
        this.limitCountDown();
        this.countdownPause();

        setInterval(() => {
            this.limitCountDown();
            if (this.stateCountDown) {
                this.setCountdown();
            }
        }, 60000);

    },
    beforeDestroy() {
        this.clearCountdownListener();
    }
};
</script>
<style lang="scss">
.modal-RSVP {
    .el-dialog {
        width: 100%;
        max-width: 829px;

        @media (max-width: 768px) {
            width: 90%;
        }
    }

    .el-dialog__header {
        display: none !important;
    }

    .el-dialog__body {
        padding: 0 !important;
    }

    .section-title {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px;

        img {
            margin-top: -85px;
        }
    }

    .bg-image {
        position: relative;

        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }

        @media (max-width: 768px) {
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }

        img {
            max-width: 100%;
        }

        .btn-close {
            position: absolute;
            right: 0;
            top: 0;
            padding: 15px;
            cursor: pointer;
            color: #ffffff;

            &:hover {
                opacity: 0.5;
            }
        }
    }

    .container {
        padding: 47px 62px;
        @media (max-width: 768px) {
            padding: 36px 38px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;

        .title {
            text-align: center;
            color: #004a34;
            font-family: JJRegular;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            margin: 0;
            padding: 0;
            /* 57.6px */
            @media (max-width: 768px) {
                font-size: 32px;
            }
        }

        .description {
            padding: 0 30px;
            color: #000;
            text-align: center;
            font-family: JJRegular;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 25.2px */
            letter-spacing: 0.54px;
            white-space: pre-line;
            word-wrap: break-word;
            overflow-wrap: break-word;
            /* 26.1px */
            @media (max-width: 768px) {
                font-size: 16px;
            }
        }

        .green-btn {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            outline: none;
            border: none;
            text-transform: uppercase;
            font-family: FontRegular;
            transition: all 125ms;
            /* identical to box height, or 144% */

            letter-spacing: 2px;

            color: #ffffff;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
                cursor: pointer;
            }

            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }
}

.count-down {
    .counter-labels {
        font-family: FontRegular;
        display: grid !important;
        overflow: hidden !important;
        grid-template-columns: 25% 1fr 25% !important;
        justify-items: stretch !important;
        justify-content: space-around !important;
        margin: 0 !important;
        padding: 0 !important;
        scale: 130% !important;

        @media (min-width: 768px) {
            grid-template-columns: repeat(3, 1fr) !important;
            scale: 125% !important;
        }
    }

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #054125;

    video {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        pointer-events: none;
        @media (max-width: 768px) {
            width: 100dvw;
            // width: auto;
        }

        .desktop {
            display: block;
        }

        .mobile {
            display: none;
        }

        @media (max-width: 768px) {
            .desktop {
                display: none;
            }
            .mobile {
                display: block;
            }
        }
    }

    .container-countdown {
        position: inherit;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
        max-width: 100dvw;

        @media (min-width: 768px) {
            width: 750px;
        }
        @media (min-width: 992px) {
            width: 970px;
        }
        @media (min-width: 1200px) {
            width: 1170px;
        }

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;

            h1 {
                padding: 0 60px;
                color: #FFF;
                text-align: center;
                font-family: JJRegular;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 112%; /* 35.84px */
                @media screen and (min-width: 769px) {
                    font-size: 55.202px;
                    line-height: 120%;
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            .sub-title-nyc {
                color: #FFF !important;
                text-align: center !important;
                font-family: JJRegular !important;
                font-size: 20px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 140% !important;
                padding: 0 30px !important;
                @media screen and (min-width: 769px) {
                    font-size: 55.202px;
                }

            }

            .sub-title {
                color: #FFF !important;
                text-align: center !important;
                font-family: JJRegular !important;
                font-size: 18px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 120% !important;
                text-transform: uppercase !important;
            }

            .sub-title-text {
                color: #FFF !important;
                text-align: center !important;
                font-family: JJRegular !important;
                font-size: 18px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 120% !important;
                max-width: 100% !important;
                padding: 0 50px;
            }

            p {
                color: #fff;
                text-align: center;
                font-family: JJ-Regular, sans-serif;
                font-style: normal;
                max-width: 100dvw;

                &:nth-child(2) {
                    font-weight: 700;
                    font-family: JJRegular, sans-serif;
                    max-width: 645px;
                    margin-top: 0;
                }

                &:nth-child(3) {
                    font-weight: 400;
                    width: 40rem;
                    max-width: calc(100dvw - 180px);
                    margin: 1rem auto;
                    @media screen and (min-width: 769px) {
                        margin: 2.5rem auto;
                    }
                }

                @media screen and (min-width: 769px) {
                    font-size: 22px;
                    line-height: 120%;
                }
            }
        }

        .count-down-footer {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: transparent;

            .normalize-text {
                padding: 0 32px;
                color: #FFF;
                text-align: center;
                font-family: JJRegular, sans-serif;
                font-size: 22px;
                font-style: normal;
                font-weight: 400;
                line-height: 118%; /* 25.96px */
                letter-spacing: 0.44px;
            }

            .sub-text-counter-nyc {
                color: #FFF !important;
                text-align: center !important;
                font-family: JJRegular !important;
                font-size: 18px !important;
                font-style: normal !important;
                font-weight: 400 !important;
                line-height: 118% !important;
                letter-spacing: 0.36px !important;
                padding: 80px 40px 0 !important;
                width: 672px !important;

                @media screen and (max-width: 400px) {
                    padding: 80px 20px 0 !important;
                }

                @media screen and (max-width: 769px) {
                    max-width: 100dvw;
                }
            }

            .date-txt {
                color: #B0C2BE !important;
                text-align: center !important;
                font-family: JJRegular !important;
                font-size: 20px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 118% !important;
                letter-spacing: 0.4px !important;
            }

            .date-txt2 {
                color: #B0C2BE !important;
                text-align: center !important;
                font-family: JJRegular !important;
                font-size: 20px !important;
                font-style: normal !important;
                font-weight: 700 !important;
                line-height: 118% !important;
                letter-spacing: 0.4px !important;
                padding-top: 50px;
            }

            p {
                color: #b0c2be;
                text-align: center;
                font-family: JJRegular, sans-serif;
                font-style: normal;
                font-weight: 700;
                max-width: 700px;

                @media screen and (min-width: 769px) {
                    font-size: 31.583px;
                    line-height: 118%;
                    letter-spacing: 0.632px;
                }
            }

            .RSVP-btn {
                width: 263px;
                height: 72px;
                border: 1px solid #004a34;
                background: #fff;
                color: #004A34;
                text-align: center;
                font-family: JJRegular;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 26px;
                letter-spacing: 2px;
                text-transform: uppercase;
                cursor: pointer;
                margin-bottom: 82px;
                @media screen and (min-width: 769px) {
                    width: 359px;
                    height: 72px;
                }
            }
        }
    }
}
</style>