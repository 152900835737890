<template>
  <el-main class="checkout_thankyou">
    <el-form
      label-position="top"
      ref="checkoutform"
      label-width="120px">
      <div class="content-form">
        <section class="check_section">
          <img :src="`${baseURL}/assets/circle-check.svg`" width="80" alt="check"/>
          <div>
            <span>Order #{{ order.uuid }}</span>
            <h1 style="margin: 0">Thank you, {{ order.firstname }}!</h1>
          </div>
        </section>

        <hr/>

        <section class="section_text">
          <h2>We received your order.</h2>

          <p>
            Your official Jameson St. Patrick’s Eve Jacket will be on the way soon, just in time for the holiday.

          </p>

          <p>
            We’ll send emails with your order confirmation and tracking info once your jacket has shipped.
          </p>

          <!-- <p>
            You will receive emails with your order number, image approval and shipping information once your decoy is ready. 
            If you have any questions about your Jameson Desk Decoy, 
            please see below our FAQs, or contact us at  <a href="mailto:deskdecoys@jamesonSPTO.com">deskdecoys@jamesonspto.com</a>
          </p> -->
        </section>
      </div>
      <section class="green_square">
        <h2>Customer information</h2>
        <div class="div_green_square">
          <div>
            <label>Contact information</label>
            <span>{{ order.email }}</span>

            <label>Shipping Address</label>
            <span
            >{{ order.shipping_address }}
            </span>

            <label>Shipping method</label>
            <span class="cart_free">*6 business days</span>
          </div>

          <div>
            <label>Payment method</label>
            <div class="payment-method-container">
              <div class="align" style="flex-direction:row;">
                <img class="card-icon" :src="`${baseURL}/assets/credit-card.svg`" width="21" alt="card"/>
                <span>Credit Card - ${{ order.total }}</span>
              </div>
            </div>

            <label>Billing Address</label>
            <span>{{ order.billing_address }}</span>
          </div>
        </div>
      </section>
      <section class="section-help">
        <p>
          If you have any questions about your Jameson St. Patrick’s Eve Jacket, please read our FAQs or contact us at <a href="mailto:jameson-jacket@jamesonspe.com">jameson-jacket@jamesonspe.com</a>
        </p>
      </section>


    </el-form>
  </el-main>
</template>


<script>

import Util from "@/lib/Util";

export default {
  name: 'CheckoutThankyou',
  props: ['orderInfo'],
  components: {},
  data() {
    return {
      baseURL: process.env.VUE_APP_URL_BASE ?? "",
    };
  },
  watch: {},
  computed: {
    order() {
      return this.orderInfo.order;
    }
  },
  methods: {},
  async mounted() {
    Util.scrollTo();
    try {
      this.$gtag.pageview('/thank-you');
    } catch(error) {
        console.warn('Page view was not tracked')
    }
  },
};
</script>


<style lang="scss" scoped>
.checkout_thankyou {

  .payment-method-container {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 30px;

    .align {
      display: flex;
      align-items: center;
      span {
        margin-bottom: 0px !important;
      }
    }
    .card-icon {
      max-width: 21px;
      display: inline-block;
    }
  }

  .logo-jameson {
    margin-left: 60px;
    margin-bottom: 50px;
  }

  @media only screen and (max-width: 769px) {
    padding: 25px 0;
  }

  form {
    width: 100%;
    @media only screen and (min-width: 769px) {
      width: 90%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

    .content-form {
      @media only screen and (max-width: 768px) {
        padding: 0 24px;
      }

    }
  }

  hr {
    color: #D1D1D1;
    margin: 30px 0;
  }

  section {
    &.check_section {
      padding: 0 30px;
      display: grid;
      grid-template-columns: 100px 1fr;

      img {
        width: 48px;
      }

      @media only screen and (max-width: 768px) {
        padding: 0;
        grid-template-columns: 1fr auto;
        gap: 18px;
      }

      div {
        align-items: flex-start;
        display: flex;
        flex-direction: column;

        span {
          color: #070707;
          font-family: 'JJRegular';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 140%;
        }

        h1 {
          font-family: 'JJRegular';
          font-style: normal;
          font-weight: 700;
          font-size: 40px;
          line-height: 140%;
          color: #154734;

          @media only screen and (max-width: 768px) {
            font-size: 28px;
          }
        }
      }
    }

    &.section_text {
      padding: 0 60px;

      @media only screen and (max-width: 768px) {
        padding: 0;
      }

      h2 {
        text-align: left;
        color: #154734;
        font-family: 'JJRegular';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
        @media only screen and (max-width: 768px) {
          font-size: 20px;
        }
      }

      p {
        text-align: left;
        font-family: 'JJLt-Regular';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        color: #070707;

        a {
          color: #880d27;
          font-weight: 700;
        }

        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }

    &.green_square {
      background-color: #f2fff4;
      text-align: left;

      padding: 20px 60px;

      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 20px 25px;
        margin-bottom: 20px
      }

      h2 {
        font-family: 'JJRg-Regular';
        color: #154734;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
      }

      .div_green_square {
        display: grid;
        grid-template-columns: 1fr 1fr;

        @media only screen and (max-width: 768px) {
          grid-template-columns: 1fr;
        }

        text-align: left;
        gap: 20px;

        label {
          font-family: 'JJLt-Regular';
          color: #686666;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 140%;
        }

        span {
          font-family: 'JJLt-Regular';
          color: #070707;
          font-style: normal;
          font-weight: 300;
          font-size: 18px;
          line-height: 140%;
          margin-top: 5px;
          margin-bottom: 30px;
        }

        div:first-child {
          display: flex;
          flex-direction: column;
        }

        div:last-child {
          display: flex;
          flex-direction: column;

          label:first-child {
            margin-bottom: 10px;
          }

          div {
            margin-bottom: 30px;

            img {
              margin-right: 10px;
            }
          }
        }
      }
    }

    &.section-help {
      padding: 0 25px;
      font-family: 'JJRegular';
    }

    &.section_help {
      padding: 20px 30px;
    }

    &.thankyou_footer {
      padding: 20px 60px;

      @media only screen and (max-width: 768px) {
        padding: 0 24px;
      }

      span {
        font-family: 'JJLt-Regular';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 140%;
        color: #070707;

        a {
          color: #880D27;
          font-weight: 700;
        }

        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
  
  .cart_free {
        font-family: "JJLt-Regular" !important;
        font-style: normal !important;
        font-weight: 700 !important;
        line-height: 26px !important;
        letter-spacing: 0.03em !important;
        color: #293644 !important;

    }


}
</style>
