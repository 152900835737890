<template>
  <div id="select-product">
    <product-gallery :images="images" :sizes="sizes"></product-gallery>
  </div>
</template>

<script>
//   import Util from "@/lib/Util";
import Swal from "sweetalert2";
import axios from "axios";
// import ProductGallery from "../components/ProductGallery/ProductGallery.vue";
import ProductGallery from "../components/ProductCarousel.vue";
import Util from "@/lib/Util";

export default {
  name: "SelectProduct",
  components: {
    ProductGallery,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_URL_BASE ?? "",
      sizes: {},
      images: [],
    };
  },
  methods: {
    async getSizes() {
      try {
        const {VUE_APP_API_BASE, VUE_APP_API_KEY} = process.env;
        const url = `${VUE_APP_API_BASE}/products?enabled=1`;

        const config = {
          headers: {
            Authorization: `Bearer ${VUE_APP_API_KEY}`,
          },
        };

        const {data} = await axios.get(url, config);

        const info = data.data.reduce((result, size) => {
          const avaliable = size.available_stock - size.reserved;

          result[size.fields[0].default_value] = {
            avaliable: avaliable > 0,
            id: size.id,
            order: size.fields[0].order,
            price: Math.floor(size.price),
          };
          return result;
        }, {});

        // Create a new object with keys sorted based on the "order" property
        const sortedSizes = Object.keys(info)
          .sort((a, b) => info[a].order - info[b].order)
          .reduce((obj, key) => {
            obj[key] = info[key];
            return obj;
          }, {});

        return sortedSizes;
      } catch (error) {
        console.error(error);
        Swal.fire({
          target: document.getElementById("app"),
          icon: "error",
          title: "Notice",
          text: "Failed to get Products",
        });
        return null;
      }
    },
  },
  async mounted() {
    Util.scrollToSelectProduct();
    this.images = [
      `${this.baseURL}/assets/slider/1.jpg`,
      `${this.baseURL}/assets/slider/2.jpg`,
      `${this.baseURL}/assets/slider/3.jpg`,
      `${this.baseURL}/assets/slider/4.jpg`,
      `${this.baseURL}/assets/slider/5.jpg`,
      `${this.baseURL}/assets/slider/6.jpg`,
      `${this.baseURL}/assets/slider/7.jpg`,
      `${this.baseURL}/assets/slider/8.jpg`,
      `${this.baseURL}/assets/slider/9.jpg`,
      `${this.baseURL}/assets/slider/10.jpg`,
      `${this.baseURL}/assets/slider/sizes.jpg`,
      // `${this.baseURL}/assets/slider/6.png`,
      // `${this.baseURL}/assets/slider/7.png`,
      // `${this.baseURL}/assets/slider/8.png`,
    ];
    this.sizes = await this.getSizes();
  },
};
</script>

<style lang="scss" scoped></style>
