<template class="login">
  <div class="login-screen">
    <h1>this is the login page</h1>
  </div>
</template>

<script>

export default {
  name: 'AuthView',
  components: {
  },
  data() {
    return {
    };
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  computed: {
  },
  methods: {
    
  },
  mounted() {
    if (this.isLoggedIn) {
      this.$router.push({ path: '/' });
    }
  },
};
</script>
