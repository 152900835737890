<template>
  <el-main class="checkout_form">
    <el-form
      :rules="rules"
      label-position="top"
      ref="checkoutform"
      :model="form"
      label-width="120px"
    >
      <div class="content-form">
        <img class="logo-jameson" :src="`${this.baseURL}/assets/jameson.svg`" />
        <h2>Check Out</h2>

        <!-- <pre>{{ taxinfo }}</pre> -->

        <el-row class="mobile-onecolumn" :gutter="20">
          <el-col :span="12">
            <el-form-item label="First Name" prop="firstname">
              <el-input
                v-model="form.firstname"
                placeholder="e.g. James"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Last Name" prop="lastname">
              <el-input
                v-model="form.lastname"
                placeholder="e.g. Jameson"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row class="mobile-onecolumn" :gutter="20">
          <el-col :span="12">
            <el-form-item label="Email" prop="email">
              <el-input
                type="email"
                v-model="form.email"
                placeholder="e.g. JJames@mail.com"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Phone Number" prop="phone">
              <el-input
                type="tel"
                v-model="form.phone"
                placeholder="e.g. +01235162733"
                v-mask="'+(###) ###-####'"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Billing Address" prop="billing_address">
              <el-input
                v-model="form.billing_address"
                placeholder="e.g. 610 NW 30TH ST"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Billing Address2" prop="billing_address2">
              <el-input
                v-model="form.billing_address2"
                placeholder="e.g. 610 NW 30TH ST"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="State" prop="billing_state">
              <el-select
                clearable
                filterable
                v-model="form.billing_state"
                placeholder="Select state"
              >
                <el-option :label="''" :value="null" selected></el-option>
                <el-option
                  v-for="item in optionsState"
                  :key="item.value"
                  :label="item.label"
                  :value="item"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="City" prop="billing_city">
              <el-input
                v-model="form.billing_city"
                placeholder="e.g. Miami"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="Zip CODE" prop="billing_zip">
              <el-input
                type="tel"
                v-model="form.billing_zip"
                placeholder="e.g. 11234"
                @input="handleChangeZipCode('billing_zip')"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="usesameaddress">
              <el-checkbox v-model="form.usesameaddress"
                ><span class="span-bold"
                  >Same Billing Address</span
                ></el-checkbox
              >
            </el-form-item>
          </el-col>
        </el-row>

        <div v-if="!form.usesameaddress" class="shipping-details-container">
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="Shipping Address" prop="shipping_address">
                <el-input
                  v-model="form.shipping_address"
                  placeholder="e.g. e.g. 610 NW 30TH ST"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="Shipping Address2" prop="shipping_address2">
                <el-input
                  v-model="form.shipping_address2"
                  placeholder="e.g. APT, STE No"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="24">
              <!-- <pre>{{ form.shipping_state }}</pre> -->
              <el-form-item label="State" prop="shipping_state">
                <el-select
                  clearable
                  filterable
                  v-model="form.shipping_state"
                  placeholder="Select state"
                >
                  <el-option :label="''" :value="null" selected></el-option>

                  <el-option
                    v-for="item in optionsState"
                    :key="item.value"
                    :label="item.label"
                    :value="item"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="City" prop="shipping_city">
                <el-input
                  v-model="form.shipping_city"
                  placeholder="e.g. Miami"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Zip CODE" prop="shipping_zip">
                <el-input
                  type="tel"
                  v-model="form.shipping_zip"
                  placeholder="e.g. 11234"
                  @input="handleChangeZipCode('shipping_zip')"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item prop="optin">
              <el-checkbox v-model="form.optin"
                ><span class="span-bold">By checking this box</span>, I agree to
                receive emails, offers, invites, and updates from Jameson and
                other trusted Pernod Ricard Brands
              </el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>

        <h2>Payment</h2>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Name on Card">
              <el-input
                v-model="form.name_on_card"
                placeholder="e.g. James"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="Card Number" prop="card_number">
              <el-input
                v-mask="'#### #### #### ####'"
                placeholder="1234 1234 1234 1234"
                v-model="form.card_number"
              >
                <i slot="prefix" class="el-input__icon el-icon-bank-card"></i>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="Expiry Date" prop="card_expiry">
              <!-- <div id="card-expiry" class="column pt-3 ps-3"></div> -->
              <el-input
                v-mask="'## / ##'"
                placeholder="01 / 25"
                v-model="form.card_expiry"
              >
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="CVC" prop="card_cvv">
              <!-- <div id="card-cvc" class="column pt-3 ps-3"></div> -->
              <el-input
                v-mask="'###'"
                placeholder="123"
                v-model="form.card_cvv"
              >
                <!-- <i slot="prefix" class="el-input__icon el-icon-search"></i> -->
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <!-- <el-row :gutter="20">
            <el-col :span="24" class="message_div">
                <p class="text_message">
                    Mauris ut donec leo adipiscing ullamcorper. Quam sed tempor lectus
                    est risus. Egestas sit aliquet mi dolor tortor in. At commodo.
                </p>
            </el-col>
        </el-row> -->

        <el-form-item>
          <div class="parent_button">
            <el-button
              :disabled="processingPayment"
              type="jameson"
              @click="onSubmit"
              >{{ processingPayment ? "..." : "Complete Order" }}
            </el-button>
          </div>
        </el-form-item>
      </div>
    </el-form>
  </el-main>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";
import { State } from "country-state-city";
import Util from "@/lib/Util";

export default {
  props: ["cart", "disable"],
  data() {
    const isValidUSPhone = (rule, value, callback) => {
      if (value == null || value === "") {
        callback(new Error("This field is required"));
      } else {
        console.log(/^[0-9\-+() ]{15}$/.test(value));
        if (/^[0-9\-+() ]{15}$/.test(value)) {
          // return true;
          callback();
          //   this.$refs.checkoutform.validateField(rule.field);
        } else {
          callback(new Error("This field is invalid"));
        }
        // callback();
      }
    };
    const isValidUSZip = (rule, value, callback) => {
      if (value == null || value === "") {
        callback(new Error("This field is required"));
      } else {
        if (/^\d{5}(-\d{4})?$/.test(value)) {
          // return true;
          callback();
          //   this.$refs.checkoutform.validateField(rule.field);
        } else {
          callback(new Error("This field is invalid"));
        }
        // callback();
      }
    };

    const isValidCardNumber = (rule, value, callback) => {
      // Remove any spaces or dashes from the input
      value = value.replaceAll(" ", "");

      // Expresiones regulares para las longitudes de los números de tarjeta de crédito
      let regexVisa = /^4\d{12}(?:\d{3})?$/;
      let regexMastercard = /^5[1-5]\d{14}$/;
      let regexAmex = /^3[47]\d{13}$/;
      let regexDiscover = /^6(?:011|5\d{2})\d{12}$/;
      let regexDinersClub = /^3(?:0[0-5]|[68]\d{1})\d{11}$/;
      let regexJCB = /^(?:2131|1800|35\d{3})\d{11}$/;
      let regexMaestro = /^(5018|5020|5038|6304|6759|6761|6763|6799)\d{12,18}$/;

      // Validar la longitud para cada tipo de tarjeta
      if (
        regexVisa.test(value) ||
        regexMastercard.test(value) ||
        regexAmex.test(value) ||
        regexDiscover.test(value) ||
        regexDinersClub.test(value) ||
        regexJCB.test(value) ||
        regexMaestro.test(value)
      ) {
        callback();
      } else {
        callback(new Error("This field is invalid"));
      }
    };
    const isValidCardExpiry = (rule, value, callback) => {
      value = value.replaceAll(" ", "");
      // Check if the input matches the MM/YY format
      if (!/^(0[1-9]|1[0-2])\/\d{2}$/.test(value)) {
        callback(new Error("This field is invalid"));
      }

      // Extract month and year from the input
      const parts = value.split("/");
      const month = parseInt(parts[0], 10);
      const year = parseInt(parts[1], 10);

      // Get the current date
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear() % 100;
      const currentMonth = currentDate.getMonth() + 1;

      // Check if the expiration date is in the future
      if (
        year > currentYear ||
        (year === currentYear && month >= currentMonth)
      ) {
        callback();
      } else {
        callback(new Error("This field is invalid"));
      }
    };
    const isValidCardCVV = (rule, value, callback) => {
      // Check if the input consists of 3 or 4 digits
      if (!/^\d{3,4}$/.test(value)) {
        callback(new Error("This field is invalid"));
      }

      callback();
    };

    return {
      baseURL: process.env.VUE_APP_URL_BASE ?? "",
      optionsState: [{}],
      options: {
        pk: process.env.VUE_APP_STRIPE_KEY,
        apiVersion: 3,
        locale: "en",
      },
      stripe: null,
      paymentRequest: null,
      paymentRequestButton: null,
      loading: false,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      processingPayment: false,
      orderInfo: null,

      form: {
        firstname: null,
        lastname: null,
        email: null,
        phone: null,
        billing_address: null,
        billing_address2: null,
        billing_state: null,
        billing_state_name: null,
        billing_city: null,
        billing_zip: null,
        shipping_address: null,
        shipping_address2: null,
        shipping_state: null,
        shipping_state_name: null,
        shipping_city: null,
        shipping_zip: null,
        referrer: null,
        utm_source: null,
        utm_campaign: null,
        utm_medium: null,
        utm_content: null,
        environment: "production",
        usesameaddress: true,
        name_on_card: null,
        optin: false,
        detail: [],
        query_params: {},
        card_number: null,
        card_expiry: null,
        card_cvv: null,
      },
      rules: {
        firstname: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
            type: "string",
          },
          { min: 3, message: "Minimum 3 characters", trigger: "blur" },
        ],
        lastname: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
            type: "string",
          },
          { min: 3, message: "Minimum 3 characters", trigger: "blur" },
        ],
        email: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
          { type: "email", message: "This field is invalid", trigger: "blur" },
        ],
        phone: [
          {
            required: true,
            message: "This field is required",
          },
          { validator: isValidUSPhone, trigger: "blur" },
        ],
        name_on_card: [{ required: true }],

        billing_address: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
            type: "string",
          },
          { min: 3, message: "Minimum 3 characters", trigger: "blur" },
        ],
        billing_address2: [],
        billing_state: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        billing_city: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
            type: "string",
          },
        ],
        billing_zip: [
          {
            required: true,
            message: "This field is required",
          },
          { validator: isValidUSZip, trigger: "blur" },
        ],
        shipping_address: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
            type: "string",
          },
          { min: 3, message: "Minimum 3 characters", trigger: "blur" },
        ],
        shipping_address2: [],
        shipping_state: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
          },
        ],
        shipping_city: [
          {
            required: true,
            message: "This field is required",
            trigger: "blur",
            type: "string",
          },
        ],
        shipping_zip: [
          {
            required: true,
            message: "This field is required",
          },
          { validator: isValidUSZip, trigger: "blur" },
        ],
        card_number: [
          {
            required: true,
            message: "This field is required",
          },
          { validator: isValidCardNumber, trigger: "blur" },
        ],
        card_expiry: [
          {
            required: true,
            message: "This field is required",
          },
          { validator: isValidCardExpiry, trigger: "blur" },
        ],
        card_cvv: [
          {
            required: true,
            message: "This field is required",
          },
          { validator: isValidCardCVV, trigger: "blur" },
        ],
      },
      productId: null,
      product: null,
      debouncedHandleBillingZipcodeChange: null,
    };
  },
  watch: {
    "form.billing_zip": {
      handler(newValue, oldValue) {
        if (newValue === oldValue) {
          return;
        }
        this.debouncedHandleBillingZipcodeChange(newValue);
      },
    },
    cart(newValue) {
      if (
        Array.isArray(newValue) &&
        newValue.length === 1 &&
        newValue[0]?.product_id
      ) {
        this.productId = newValue[0]?.product_id;
        this.product = newValue[0];
        return;
      }
      this.productId = null;
      this.product = null;
    },
  },
  methods: {
    handleChangeZipCode(input) {
      const value = this.form[input];

      let response = value.replaceAll("-", "");
      if (value.length > 5) {
        response = response.substring(0, 5) + "-" + response.substring(5);
      }
      this.$set(this.form, input, response);
      //return response;
    },

    validateForm(ref) {
      return new Promise((resolve) => {
        this.$refs[ref].validate((valid) => {
          console.log(valid);
          if (valid) {
            return resolve(true);
          }
          resolve(false);
        });
      });
    },
    async onSubmit() {
      // let intent, payment;
      try {
        this.processingPayment = true;
        console.log("submit!");

        this.$gtag.event("Click", {
          event_category: "Button Click",
          event_label: "Complete Order",
          value: "",
        });

        const isValid = await this.validateForm("checkoutform");

        if (!isValid) {
          //throw ('Validate Form Fields');
          console.error("Validate Form Fields");
          return;
        }
        // if(this.orderInfo == null) {
        this.form.detail = [...this.cart];

        // Update address if same address is selected
        if (this.form.usesameaddress) {
          this.form.shipping_address = this.form.billing_address;
          this.form.shipping_address2 = this.form.billing_address2;
          this.form.shipping_state = this.form.billing_state;
          this.form.shipping_state_name = this.form.billing_state_name;
          this.form.shipping_city = this.form.billing_city;
          this.form.shipping_zip = this.form.billing_zip;
        }

        let order = { ...this.form };
        order.billing_state_name = this.form.billing_state.label;
        order.billing_state = this.form.billing_state.value;
        order.shipping_state_name = this.form.shipping_state.label;
        order.shipping_state = this.form.shipping_state.value;

        if (this.form.usesameaddress) {
          order.shipping_state_name = this.form.billing_state.label;
          order.shipping_state = this.form.billing_state.value;
        }

        this.orderInfo = await this.createOrder(order);

        if (
          // this.orderInfo.order.stripe_intent_metadata.responsetext !== "SUCCESS"
          this.orderInfo.order.stripe_intent_metadata.response !== '1'
          ) {
          console.error(this.orderInfo.order.stripe_intent_metadata);
          throw "Unable to process payment";
        }

        this.$emit("payment", this.orderInfo);

        Swal.fire({
          target: document.getElementById("app"),
          icon: "success",
          title: "Jameson SPE Jacket",
          text: `${this.orderInfo.order.detail[0].name}`,
        });
        // return [intent, payment];
      } catch (error) {
        console.error(error);
        let message = error?.response?.data?.message ?? null;
        if (!message) {
          message =
            error?.message ?? "Unable to process payment at this time...";
        }

        const config = {
          status: "failed",
          message: message,
          release_reserve: true,
        };
        const uuid = this?.orderInfo?.order_id ?? null;

        if (uuid) {
          const cancelOrderResult = await this.cancelOrder(uuid, config);
          console.error(cancelOrderResult);
        }

        Swal.fire({
          target: document.getElementById("app"),
          icon: "error",
          title: "Error!",
          text: message,
        });
      } finally {
        this.processingPayment = false;
      }
    },
    async loadStripe() {
      const { VUE_APP_STRIPE_KEY } = process.env;
      await loadStripe(VUE_APP_STRIPE_KEY);
      // const { options } = this;
      (this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_KEY, null)),
        await this.loadElements();
    },
    loadElements() {
      const elements = this.stripe.elements({
        fonts: [
          {
            cssSrc:
              "https://nyc3.digitaloceanspaces.com/archeio/sdk/common/fonts/gotham/gotham_regular.css",
          },
        ],
      });

      const elementProps = {
        style: {
          base: {
            iconColor: "#111319",
            color: "#111319",
            fontWeight: 400,
            fontFamily:
              "Gotham_Regular, Helvetica Neue, Helvetica, Arial, sans-serif",
            fontSize: "14px",
            fontSmoothing: "antialiased",
            height: "100% !important",
            display: "flex !important",
            "align-items": "center !important",

            "::placeholder": {
              color: "#D5DADD",
            },

            ":-webkit-autofill": {
              color: "#111319",
            },
          },
          invalid: {
            iconColor: "#EE5656",
            color: "#EE5656",
          },
        },
        classes: {
          focus: "has-focus",
          empty: "is-empty",
          invalid: "is-invalid",
        },
      };

      const elementCardProps = {
        showIcon: true,
        iconStyle: "solid",
        ...elementProps,
      };

      this.cardNumber = elements.create("cardNumber", elementCardProps);
      this.cardExpiry = elements.create("cardExpiry", elementProps);
      this.cardCvc = elements.create("cardCvc", elementProps);

      this.paymentRequest = this.stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Total",
          amount: Math.round(20 * 100),
        },
        requestShipping: false,
        requestPayerName: true,
        requestPayerEmail: true,
        shippingOptions: [],
      });

      this.paymentRequestButton = elements.create("paymentRequestButton", {
        paymentRequest: this.paymentRequest,
      });

      // Mount the element
      this.cardNumber.mount("#card-number");
      this.cardExpiry.mount("#card-expiry");
      this.cardCvc.mount("#card-cvc");
    },
    async getPaymentIntent(amount) {
      const { VUE_APP_API_BASE } = process.env;
      const url = `${VUE_APP_API_BASE}?action=intent&amount=${amount}&environment=production`;
      const { data } = await axios.get(url);
      return data;
    },
    async performPayment(intentID, orderUUID) {
      try {
        const billing = {
          name: this.form.firstname,
          email: this.form.email,
        };
        const wallet = false;

        const handleActions = wallet ? { handleActions: false } : {};

        // eslint-disable-next-line camelcase
        const payment_method = {
          card: this.cardNumber,
          billing_details: billing,
        };

        const result = await this.stripe.confirmCardPayment(
          intentID,
          { payment_method },
          handleActions
        );
        const { error, paymentIntent } = result;
        if (error) {
          // console.error(error);
          // throw (`Unable to verify you payment.
          // Please leave us a message with the payment: ${paymentIntent.id}`);
          throw error;
        }
        if (paymentIntent.status === "succeeded") {
          const orderValidationResult = await this.processOrder(orderUUID);
          // console.log(orderValidationResult);
          // console.log(result.intent_paid);
          return {
            // paymentSuccess: true,
            paymentSuccess: orderValidationResult?.intent_paid ?? false,
            paymentIntent,
          };
        }
        return {
          paymentSuccess: false,
          paymentIntent,
        };
      } catch (error) {
        console.error(error);
        throw error;
        // throw (`Unable to verify you payment.
        //   Please leave us a message with the payment: ${intentID}`);
      }
    },
    async cancelOrder(orderUUID, cancelConfig = {}) {
      try {
        if (!orderUUID) {
          throw "Missing order uuid";
          // return;
        }

        const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
        const url = `${VUE_APP_API_BASE}/orders/${orderUUID}/cancel`;
        const config = {
          headers: {
            Authorization: `Bearer ${VUE_APP_API_KEY}`,
          },
        };
        const payload = { ...cancelConfig };
        const { data } = await axios.post(url, payload, config);
        return data ?? null;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async getTaxRate(zip) {
      try {
        if (!zip) {
          throw "Missing Zipcode";
          // return;
        }

        const isValidUSZip = (sZip) => {
          return /^\d{5}(-\d{4})?$/.test(sZip);
        };

        if (!isValidUSZip) {
          throw "Invalid US Zipcode";
          // return;
        }

        const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
        const url = `${VUE_APP_API_BASE}/tax/${zip}`;
        const config = {
          headers: {
            Authorization: `Bearer ${VUE_APP_API_KEY}`,
          },
        };
        const { data } = await axios.get(url, config);
        return data ?? null;
      } catch (error) {
        console.error(error);
      }
    },
    handleBillingZipcodeChange(zip) {
      this.$emit("zipUpdated", zip);
    },
    async createOrder(form) {
      try {
        if (!form) {
          throw "Missing form info";
          // return;
        }

        const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
        const url = `${VUE_APP_API_BASE}/orders`;
        const config = {
          headers: {
            Authorization: `Bearer ${VUE_APP_API_KEY}`,
          },
        };
        let payload = { ...form };

        payload = Util.signToken(payload);

        const { data } = await axios.post(url, { data: payload }, config);
        return data ?? null;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async processOrder(orderId) {
      try {
        if (!orderId) {
          throw "Missing order id";
          // return;
        }

        const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
        const url = `${VUE_APP_API_BASE}/orders/payment/${orderId}/process`;
        const config = {
          headers: {
            Authorization: `Bearer ${VUE_APP_API_KEY}`,
          },
        };

        const { data } = await axios.get(url, config);
        return data ?? null;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    loadCountries() {
      let states = State.getStatesOfCountry("US");
      let options = [];
      for (let index = 0; index < states.length; index++) {
        const element = states[index];
        options.push({
          label: element.name,
          value: element.isoCode,
        });
      }

      this.optionsState = options;
    },

    validateCardNumber(cardNumber, cvv, expirationDate) {
      // Remove any non-digit characters
      cardNumber = cardNumber.replace(/\D/g, "");

      // Check if the card number is 16 digits long (standard for most cards)
      if (cardNumber.length !== 16) {
        return false;
      }

      // Luhn algorithm check for card number
      var sum = 0;
      var doubleUp = false;
      for (var i = cardNumber.length - 1; i >= 0; i--) {
        var curDigit = parseInt(cardNumber.charAt(i), 10);
        if (doubleUp) {
          curDigit *= 2;
          if (curDigit > 9) {
            curDigit -= 9;
          }
        }
        sum += curDigit;
        doubleUp = !doubleUp;
      }
      if (sum % 10 !== 0) {
        return false;
      }

      // Check if CVV is 3 or 4 digits long
      if (cvv.length !== 3 && cvv.length !== 4) {
        return false;
      }

      // Check if expiration date is in the future
      var currentDate = new Date();
      var currentMonth = currentDate.getMonth() + 1; // Month is zero-based
      var currentYear = currentDate.getFullYear();
      var expirationMonth = parseInt(expirationDate.substring(0, 2), 10);
      var expirationYear = parseInt(expirationDate.substring(3, 7), 10);

      if (
        expirationYear < currentYear ||
        (expirationYear === currentYear && expirationMonth < currentMonth)
      ) {
        return false;
      }

      // Additional CVV validation logic (optional)

      return true;
    },
  },
  async mounted() {
    try {
      this.$gtag.pageview("/order-form");
    } catch (error) {
      console.warn("Page view was not tracked");
    }
    this.debouncedHandleBillingZipcodeChange = _.debounce(
      (zip) => this.handleBillingZipcodeChange(zip),
      500
    );
    this.loadStripe();
    this.loadCountries();
    setTimeout(() => {
      const inputsStripe = document.querySelectorAll(".__PrivateStripeElement");
      inputsStripe.forEach((item) => {
        item.style.cssText +=
          "margin: 0px !important;\n" +
          "    padding: 0px !important;\n" +
          "    border: none !important;\n" +
          "    display: flex !important;\n" +
          "    background: transparent !important;\n" +
          "    position: relative !important;\n" +
          "    opacity: 1 !important;";
      });
    }, 2000);

    setInterval(() => {
      const inputsStripe = document.querySelectorAll(".__PrivateStripeElement");
      inputsStripe.forEach((item) => {
        item.parentElement.classList.add("has-focus");
      });
    }, 500);

    this.form.utm_source = this.queryParams.utm_source;
    this.form.utm_campaign = this.queryParams.utm_campaign;
    this.form.utm_medium = this.queryParams.utm_medium;
    this.form.utm_content = this.queryParams.utm_content;
    this.form.query_params = this.queryParams;
  },
  beforeUnmount() {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  computed: {
    // a computed getter
    queryParams() {
      const params = new URLSearchParams(window.location.search);
      let queryParam = {};

      for (const param of params) {
        queryParam[param[0]] = param[1];
      }
      return queryParam;
    },
  },
};
</script>

<style lang="scss">
.checkout_form {
  .mobile-onecolumn {
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      & > div {
        width: 100% !important;
      }
    }
  }

  .is-empty,
  .has-focus,
  .is-complete {
    background-color: #fff;
    border-radius: 0px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    width: 100%;
  }
  .parent_button {
    display: flex;
    justify-content: center;
  }

  .content-form {
    @media only screen and (max-width: 768px) {
      padding: 20px;
    }
    .logo-jameson {
      height: auto;
      width: 200px;
      margin-bottom: 20px;
    }
  }

  h2 {
    text-align: initial;
    font-family: "JJRegular";
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 140%;
    color: #154734;
  }

  .text_message {
    max-width: 617px;
    text-align: center;
    font-family: "JJRegular";
    font-size: 16px;
  }

  .logo_jameson {
    max-width: 196px;
    margin-bottom: 41px;
  }
}

.message_div {
  display: flex;
  align-content: center;
  justify-content: center;
}

.__PrivateStripeElement {
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
}
</style>