<template>
    <section class="product-carousel-main-container">
        <div
            class="carousel-container"
            @mousedown.prevent="startDrag"
            @mousemove="onDrag"
            @mouseup="endDrag"
            @mouseleave="endDrag"
            @touchstart="startDrag"
            @touchmove="onDrag"
            @touchend="endDrag"
        >
            <div
                class="carousel-track"

                :style="{ transform: `translateX(${-currentImageIndex * 100}%) translateY(0)` }"
                ref="track"
            >
                <!-- Duplicate the last image for infinite effect -->
                <img
                    :src="images[images.length - 1]"
                    :alt="'Image ' + (images.length - 1)"
                    class="carousel-image"
                    data-scale="2.0"
                    :data-image="images[images.length - 1]"
                />
                <!-- Display all images -->
                <img
                    v-for="(image, index) in images"
                    :src="image"
                    :key="index"
                    :alt="'Image ' + index"
                    class="carousel-image"
                    :class="{ selected: (index + 1) === currentImageIndex}"
                    data-scale="2.0"
                    :data-image="image"
                />
                <!-- Duplicate the first image for infinite effect -->
                <img
                    :src="images[0]"
                    :alt="'Image 0'"
                    class="carousel-image"
                    data-scale="2.0"
                    :data-image="images[0]"
                />
            </div>
            <div class="navigation-arrows">
                <button
                    class="nav-arrow left"
                    @touchstart="move('prev')"
                    @click.stop="move('prev')"
                >
                    <svg
                        width="23"
                        height="19"
                        viewBox="0 0 23 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M22.166 9.26438C22.166 9.93131 21.642 10.4553 21.0227 10.4553H4.82575L11.1616 16.4577C11.638 16.8865 11.638 17.6487 11.2093 18.0774C10.7805 18.5538 10.0659 18.5538 9.58956 18.1251L1.20526 10.1219C0.96707 9.88367 0.871794 9.59784 0.871794 9.26438C0.871794 8.97855 0.96707 8.69272 1.20526 8.45453L9.58956 0.451331C10.0659 0.022588 10.7805 0.022588 11.2093 0.498969C11.638 0.927712 11.638 1.68992 11.1616 2.11866L4.82575 8.12106H21.0227C21.6896 8.12106 22.166 8.64508 22.166 9.26438Z"
                            fill="#FFF7E0"
                        />
                    </svg>
                </button>
                <button
                    class="nav-arrow right"
                    @touchstart="move('next')"
                    @click.stop="move('next')"
                >
                    <svg
                        width="23"
                        height="19"
                        viewBox="0 0 23 19"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M13.4482 0.451331L21.8326 8.45453C22.0707 8.69272 22.2137 8.97855 22.2137 9.31201C22.2137 9.59784 22.0707 9.88367 21.8326 10.1219L13.4482 18.1251C13.0195 18.5538 12.2573 18.5538 11.8286 18.0774C11.3998 17.6487 11.3998 16.8865 11.8762 16.4577L18.2121 10.4553H2.01511C1.34817 10.4553 0.871794 9.93131 0.871794 9.31201C0.871794 8.64508 1.34817 8.1687 2.01511 8.1687H18.2121L11.8762 2.11866C11.3998 1.68992 11.3998 0.927712 11.8286 0.498969C12.2573 0.022588 12.9719 0.022588 13.4482 0.451331Z"
                            fill="#FFF7E0"
                        />
                    </svg>
                </button>
            </div>
            <div class="thumbnails">
                <div
                    v-for="(image, index) in images"
                    :key="'thumb-' + index"
                    class="thumbnail"
                    :class="{ selected: currentImageIndex === index + 1 }"
                    @click="selectImage(index)"
                >
                    <img v-show="!false" :src="image" :alt="'Thumbnail ' + index"/>
                </div>
            </div>
        </div>
        <section class="container-card">
            <template v-if="!soldOut">
                <h1>Jameson <br> St. Patrick’s Eve Jacket</h1>
                <p>
                    Our limited-edition St. Patrick’s Eve jacket features a hidden pocket to hold the
                    Jameson hip flask that comes with it (you’re welcome), plus an array of luxe patches
                    signature to the brand. All proceeds from jacket sales will go to the Restaurant
                    Workers' Community Fund.
                    <br>
                    <br>
                    <a class="make_sure" @click="showModalSizes = !showModalSizes">Make sure to check the size chart to
                                                                                   find your perfect fit.</a>
                    <br>
                    <br>
                    <span class="all_sales">All sales are final—no returns or exchanges.</span>
                </p>

                <section class="container-section1">
                    <span> ${{ price }} </span>
                    <span> FREE SHIPPING </span>
                </section>

                <section class="main-container-sizes">
                    <span class="select-size"> Select size </span>

                    <div class="container-sizes">
                        <template v-for="({ avaliable, id: idProduct }, size) in sizes">
                            <div
                              
                                :key="avaliable + size"
                                class="squares"
                                :class="{
                  disabled: !avaliable,
                  active: itemSelected == idProduct,
                }"
                                @click="changeSelectedSize"
                            >
                                <section v-if="!avaliable" class="line"></section>
                                <span class="text">{{ size?.toUpperCase() }}</span>
                            </div>
                        </template>
                    </div>

                    <div class="container-cta">
                        <button class="buy-button" @click="openModalOrder()">
                            ORDER NOW
                        </button>

                        <p class="term-conditions">
                            By clicking <b>"ORDER NOW"</b>, you agree to our
                            <a target="_blank"
                               href="https://live-jameson.pantheonsite.io/en-us/st-patricks-day-terms-and-conditions">terms
                                                                                                                      and
                                                                                                                      conditions.</a>
                        </p>
                    </div>
                </section>
            </template>
            <div v-else class="container-sections">
                <section
                    class="section-1"
                    :style="{
            'background-image': `url(${require('@/assets/sign-up-bg-mb.jpg')})`,
          }"
                >
                    <img
                        width="243"
                        :src="`${baseURL}/assets/init/jacket-without.png`"
                        alt="jacket_img"
                    />
                </section>
                <section class="section-2">
                    <h2>Try again next week</h2>
                    <p>
                        This week your jacket size is already sold out. But check back! We’ll release 50 more next
                        Tuesday at 9 a.m ET.
                    </p>
                    <button class="buy-button" @click="goToHome()">RETURN HOME</button>
                </section>
            </div>
        </section>

        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :visible.sync="showModalSizes"
            width="fit-content"
            class="init-modal"
        >
            <div class="bg-image">
        <span class="btn-close" @click="showModalSizes = false"
        ><svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path d="M2 2L26 26M26 2L2 26" stroke="#004a34" stroke-width="3"/>
          </svg>
        </span>
            </div>

            <div class="container">
                <img :src="`${this.baseURL}/assets/slider/sizes.jpg`" alt="img_sizes"/>
            </div>
        </el-dialog>
        <el-dialog
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false"
            :visible.sync="showOrderModal"
            class="order-modal"
        >
            <div class="bg-image">
                <section
                    class="section-title"
                    :style="{'background-image': `url('${baseURL}/assets/bg-order-modal.png')`}">
                    <img
                        :src="`${baseURL}/assets/jacket-order-modal.png`"
                        alt="jacket_img"
                    />
                </section>
                <span class="btn-close" @click="showOrderModal = false"
                ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                >
            <path d="M2 2L26 26M26 2L2 26" stroke="white" stroke-width="3"/>
          </svg>
        </span>
            </div>

            <div class="container">
                <h1 class="title">St. Patrick's Eve Jacket</h1>
                <p class="description">All orders are packaged, shipped and fulfilled by Logistical.</p>
                <button class="green-btn" type="button" @click="goToOrderCart()">Accept</button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import axios from "axios";

export default {
    props: {
        images: Array,
        sizes: Object,
    },
    data() {
        return {
            showOrderModal: false,
            showModalSizes: false,
            soldOut: this.getStockSoldOut,
            baseURL: process.env.VUE_APP_URL_BASE ?? "",
            currentImageIndex: 1, // Start at 1 because the first item is a duplicate of the last
            isDragging: false,
            itemSelected: null,
            price: 0,
            startX: 0,
        };
    },
    watch: {
        currentImageIndex(val) {
          // if(val) return;
          // console.log(val);
            // let element = document.querySelectorAll(".thumbnail")[val - 1];
            // element.scrollIntoView({
            //     behavior: "smooth",
            //     block: "end",
            //     inline: "nearest",
            // });
            const amount =  (88 * (val -1) + 10);
            document.querySelector('#select-product > section > div.carousel-container > div.thumbnails').scrollLeft = amount;
        },
        sizes(val, oldVal) {
            if (val === oldVal) return;

            const size = Object.keys(val)[0];
            // this.itemSelected = size;
            this.price = this.sizes[size]?.price;
        },
    },
    computed: {
        ...mapGetters(["getStockSoldOut"]),
    },
    methods: {
        ...mapMutations(["setItemSelected", "setStockSoldOut"]),
        changeSelectedSize(e) {
            let size = "";

            if (e.target.localName === "section") {
                size = e.target.nextSibling.innerText.toLowerCase();
            } else {
                size = e.target.innerText.toLowerCase();
            }

            if (this.sizes[size].avaliable) {
                this.itemSelected = this.sizes[size].id;
                this.setItemSelected(this.sizes[size].id);
                this.price = this.sizes[size].price;
            }
        },
        openModalOrder() {
            if (this.itemSelected) {
                this.showOrderModal = true;
            }
        },
        goToOrderCart() {
            if (this.itemSelected) {
                this.$router.push({
                    path: "/order-form",
                    params: { id: this.itemSelected },
                });
            }
        },
        goToHome() {
            this.$router.push("/");
        },
        startDrag(event) {
            this.isDragging = true;
            this.startX = event.type.includes("mouse")
                ? event.clientX
                : event.touches[0].clientX;
        },
        onDrag(event) {
            if (!this.isDragging) return;
            let currentX = event.type.includes("mouse")
                ? event.clientX
                : event.touches[0].clientX;
            let dx = currentX - this.startX;
            if (dx > 50 && this.currentImageIndex > 0) {
                this.move("prev");
                this.endDrag();
            } else if (dx < -50 && this.currentImageIndex < this.images.length + 1) {
                this.move("next");
                this.endDrag();
            }
        },
        endDrag() {
            this.isDragging = false;
        },
        selectImage(index) {
            // Adjust index for the duplicate at the start
            this.currentImageIndex = index + 1;
            // this.currentImageIndex = index ;
        },
        move(direction) {
            if (direction === "prev") {
                if (this.currentImageIndex <= 1) {
                    // Jump to the last image (the duplicate)
                    this.currentImageIndex = this.images.length;
                } else {
                    this.currentImageIndex--;
                }
            } else {
                this.currentImageIndex++;
                if (this.currentImageIndex >= this.images.length + 1) {
                    // Jump back to the first image (the duplicate)
                    this.currentImageIndex = 1;
                }
            }
        },
        async getProductsStock() {
            try {
                const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
                const url = `${VUE_APP_API_BASE}/products/stock`;

                const config = {
                    headers: {
                        Authorization: `Bearer ${VUE_APP_API_KEY}`,
                    },
                };

                const { data } = await axios.get(url, config);

                const { available_stock } = data;

                return available_stock ?? 0;
            } catch (error) {
                console.error(error);
                return 0;
            }
        },
        initZoom() {
            const tiles = document.querySelectorAll(".carousel-image");
            if (tiles) {
                tiles.forEach((tile) => {

                    // tile mouse actions
                    tile.addEventListener("mouseover", function () {

                        if (!tile.classList.contains("selected")) {
                            // eslint-disable-next-line no-debugger
                            // debugger;
                            console.log(tile);
                            console.log(tile.classList.contains("selected"));
                            return;
                        } else {
                            tile.style.transform =
                                "scale(" + tile.getAttribute("data-scale") + ")";
                        }
                    });

                    tile.addEventListener("mouseout", function () {
                        // tile.style.transform = "scale(1)";
                        tile.removeAttribute('style');
                    });

                    tile.addEventListener("mousemove", function (e) {
                        const photo = tile;
                        const transformOriginX =
                            (e.pageX / tile.clientWidth) * 100;
                        photo.style.transformOrigin =
                            transformOriginX + "% " + 20 + "%";
                    });
                });
            }
        },
    },
    async mounted() {
        const stock = await this.getProductsStock();
        if (stock === 0) {
            this.setStockSoldOut(true);
            this.soldOut = true;
        }

        // this.initZoom();
    },
};
</script>

<style lang="scss">
.product-carousel-main-container {
    .order-modal {
        /*margin: 0 20px;*/

        .el-dialog {
            width: calc(100dvw - 40px);
            max-width: 600px !important;
        }

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            padding: 0 !important;
        }

        .bg-image {
            position: relative;

            .section-title {
                display: flex;
                justify-content: center;

                img {
                    margin-top: -50px;
                    margin-bottom: -50px;
                    width: auto;
                }
            }

            .btn-close {
                position: absolute;
                right: 0;
                top: 0;
                padding: 15px;
                cursor: pointer;
                color: #ffffff;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .container {
            // padding: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 60px 40px 40px;

            @media only screen and (max-width: 400px) {
                padding: 60px 22px 40px;
            }

            .title {
                color: #004a34;
                font-family: JJRegular !important;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                max-width: 268px;
                text-align: center;
                margin-bottom: 10px;
                /* 57.6px */
            }

            .description {
                color: #000;
                font-family: JJRegular;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                max-width: 350px;
                margin-top: 0;
                margin-bottom: 30px;
                text-align: center;
                /* 26.1px */
            }

            .green-btn {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                outline: none;
                border: none;
                text-transform: uppercase;
                font-family: FontRegular;
                transition: all 125ms;
                /* identical to box height, or 144% */

                letter-spacing: 2px;

                color: #ffffff;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }

                &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }
    }

    .init-modal {
        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            padding: 0 !important;
        }

        .bg-image {
            position: relative;

            img {
                max-width: 100%;
            }

            .btn-close {
                position: absolute;
                right: 0;
                top: 0;
                padding: 15px;
                cursor: pointer;
                color: #004a34;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .container {
            // padding: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
                max-width: 100%;
            }

            .title {
                color: #004a34;
                font-family: JJRegular;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 57.6px */
            }

            .description {
                color: #000;
                font-family: JJRegular;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                /* 26.1px */
            }

            .green-btn {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                outline: none;
                border: none;
                text-transform: uppercase;
                font-family: FontRegular;
                transition: all 125ms;
                /* identical to box height, or 144% */

                letter-spacing: 2px;

                color: #ffffff;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }

                &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }
    }

    display: grid;
    width: 100vw;
    max-width: 100%;
    min-height: 100vh;
    align-items: center;
    grid-template-columns: auto minmax(350px, 500px);

    @media only screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
    }

    * {
        color: #000000;
    }

    .carousel-container {
        display: grid;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        width: 100%;
        min-height: 100vh;
        background-color: #f2fff4;
        padding: 0 0 6rem 0;

        @media only screen and (max-width: 767px) {
            // max-height: 550px;
            max-width: 100dvw;
            margin: auto;
            min-height: auto;
            padding: 0;
            padding-bottom: 45px;
            // height: 100vh;
        }

        .carousel-track {
            display: flex;
            transition: transform 0.5s ease;
            scroll-snap-type: x mandatory;
            max-height: 650px;
            padding-top: 45px;
            padding-bottom: 45px;
            

            .carousel-image {
                // cursor: zoom-in;
                scroll-snap-align: start;
                flex: 0 0 100%;
                width: 100%;
                object-fit: contain;
                object-position: top;
                // transform-origin: center center;
                scale: 1;
                //   @media only screen and (min-width: 767px) {
                //     scale: 1.5;
                // }
            }
        }

        .navigation-arrows {
            padding: 46px;
            top: 40%;
            position: absolute;
            width: 100%;
            display: flex;
            justify-content: space-between;
            transform: translateY(-50%);
            pointer-events: none;

            @media only screen and (max-width: 767px) {
                top: 86%;
                padding: 10px;
            }

            @media only screen and (max-width: 380px) {
                padding: 5px;
                top: 85%;
            }

            .nav-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0;
                border: solid 1px;
                width: 57px;
                height: 57px;
                background: #004a34;
                font-size: 2rem;
                cursor: pointer;
                text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
                user-select: none;
                pointer-events: all;

                &:focus {
                    outline: none;
                }
            }

            .left {
                align-self: flex-start;
            }

            .right {
                align-self: flex-end;
            }
        }


        .thumbnails {
            margin-top: 1rem;
            display: flex;
            justify-content: flex-start;
            width: 45dvw;
            margin: auto;
            height: 100px;
            overflow-x: auto;
            column-gap: 10px;
            z-index: 9;
            height: fit-content;
            scroll-margin-bottom: 100px;
            scroll-padding-bottom: 100px;

            @media only screen and (min-width: 767px) {
                padding-bottom: 10px;
            }

            /* Add a custom scrollbar style */
            &::-webkit-scrollbar {
                width: 1px; /* Set the width of the scrollbar */
                height: 4px;
            }

            &::-webkit-scrollbar-track {
                background-color: #f1f1f1; /* Set the color of the scrollbar track */
            }

            &::-webkit-scrollbar-thumb {
                background-color: #888; /* Set the color of the scrollbar thumb */
                border-radius: 6px; /* Round the corners of the thumb */
            }

            &::-webkit-scrollbar-thumb:hover {
                background-color: #555; /* Change the color of the thumb on hover */
            }

            /* Apply overflow-x to enable horizontal scrolling */
            .scroll-container {
                overflow-x: auto;
                white-space: nowrap; /* Prevent line breaks for inline elements */
            }

            /* Optionally, add some styling to the content inside the scroll container */
            .scroll-content {
                display: inline-block;
                padding: 1px;
                /* Add other styling as needed */
            }


            // @media only screen and (min-width: 767px) {
            //   width: 80dvw;
            //   column-gap: 31px;

            //   display: grid;
            //   grid-template-columns: repeat(auto-fit, 88px);
            //   justify-content: center;
            // }

            .thumbnail {
                background: #fff;
                background-clip: border-box;
                min-width: 88px;
                width: 88px;
                height: 88px;
                overflow: hidden;
                // margin: 0 31px;
                cursor: pointer;

                scroll-margin-bottom: 200px;
                scroll-padding-bottom: 200px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

                &.selected {
                    border: 3.882px solid #004a34; // Border color for selected thumbnail
                }
            }
        }
    }

    .container-card {
        display: flex;
        flex-direction: column;
        padding: 50px 60px;
        height: 100dvh;
        justify-content: center;
        max-width: 100dvw;

        @media only screen and (max-width: 767px) {
            height: fit-content;
            justify-content: start;
            padding: 15px 20px 0px 20px;
            max-width: 100dvw;
            margin: auto;
        }

        &:has(> div.container-sections) {
            align-items: center;
            background-color: #010101de;

            @media only screen and (max-width: 767px) {
                height: 720px;
                justify-content: center;
                padding: 15px 40px 0px 40px;
            }
        }

        h1 {
            font-size: 55.2px;
            margin-bottom: 5px;
            color: #004a34;
            font-size: 34px;
            font-family: JJRegular;
            padding: 0 50px 0 0;

            @media only screen and (max-width: 767px) {
                color: #004A34;
                padding: 0 25px;
                text-align: center;
                font-family: JJRegular;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%; /* 36px */
            }
        }

        p {
            color: #293644;
            text-align: left;
            font-family: JJRegular;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 22.4px */
            letter-spacing: 0.48px;
            padding: 0 50px 0 0;

            a.make_sure {
                /*padding: 30px 30px 0;*/
                display: block;
                color: #004A34;
                text-decoration: underline;
                cursor: pointer;
            }

            span.all_sales {
                font-weight: 700;
                color: #000000;
                text-decoration: none;
            }

            @media only screen and (max-width: 767px) {
                font-size: 16px;
                text-align: center;
                padding: 25px 25px 0;
            }
        }

        .container-section1 {
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            margin-bottom: 50px;

            span {
                font-family: JJRegular;
                font-size: 19px;

                &:first-child {
                    font-size: 34.2px;
                    margin-bottom: 5px;

                    @media only screen and (max-width: 767px) {
                        font-size: 60px;
                        text-align: center;
                        margin-top: 20px;
                    }
                }

                &:nth-child(2) {
                    font-size: 20px;
                    margin-bottom: 5px;

                    @media only screen and (max-width: 767px) {
                        font-size: 25px;
                        text-align: center;
                    }
                }
            }
        }

        .main-container-sizes {
            span.select-size {
                font-size: 18px;
                color: #000000;
                font-family: JJRegular;

                @media only screen and (max-width: 767px) {
                    font-size: 20px;
                    width: 100%;
                    display: block;
                    text-align: center;
                    margin-bottom: 20px;
                }
            }

            .container-sizes {
                display: flex;
                flex-direction: row;
                gap: 15px;
                margin-top: 5px;
                @media only screen and (max-width: 767px) {
                    justify-content: space-between;
                }

                .line {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(
                            45deg,
                            transparent 49%,
                            #000000 49%,
                            #000000 51%,
                            transparent 51%
                    );
                    z-index: 1; /* Set a higher z-index for the diagonal line */
                }

                .squares {
                    position: relative;
                    overflow: hidden;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 60px;
                    height: 60px;
                    border: 2px solid #000000;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    span {
                      display: flex;
                      width: 100%;
                      height: 100%;
                      flex: 1;
                      justify-content: center;
                      align-items: center;
                    }

                    cursor: pointer;
                    color: #363738 !important;
                    text-align: center;
                    font-family: JJRegular;
                    font-size: 22.144px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    @media only screen and (max-width: 767px) {
                        font-size: 18px;
                    }

                    &.active {
                        background-color: #004a34;

                        span {
                            color: #fff;
                        }
                    }

                    &.disabled {
                        cursor: auto;

                        span {
                            color: #b0b1b1;
                            position: relative;
                            z-index: 0;
                        }
                    }
                }
            }

            .container-cta {

                .term-conditions {
                    color: #000;
                    text-align: center;
                    font-family: JJRegular;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%; /* 22.4px */
                    a {
                        color: #880D27;
                        font-family: JJRegular;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 140%;
                        text-decoration-line: underline;

                        width: fit-content;
                        display: inline-block !important;
                    }
                }

                display: flex;
                flex-direction: column;
                margin-top: 20px;
                @media only screen and (max-width: 767px) {
                    margin-bottom: 40px;
                }

                a {
                    cursor: pointer;
                    margin-bottom: 50px;
                    text-decoration: underline;
                    font-family: JJRegular;

                    @media only screen and (max-width: 767px) {
                        font-size: 20px;
                        display: block !important;
                        width: 100%;
                        text-align: center;
                    }

                    &:hover {
                        opacity: 0.5;
                    }
                }

                button {
                    margin: auto;
                    width: 100%;
                }

                span {
                    text-align: center;
                    font-size: 16px;
                    font-family: JJRegular;
                    max-width: 330px;
                    margin: auto;
                    margin-top: 20px;

                    a {
                        color: #880D27;
                    }
                }
            }
        }

        .buy-button {
            background-color: #004a34;
            color: #fff;
            cursor: pointer;
            font-family: JJRegular;
            border: none;
            color: #FFF;

            text-align: center;
            font-family: JJRegular;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.36px;
            text-transform: uppercase;

            width: 353px;
            height: 47.2px;
            font-size: 18px;
            margin-bottom: 20px;

            &:hover {
                opacity: 0.5;
            }

            @media only screen and (max-width: 767px) {
                font-family: JJRegular;
                width: 100%;
                height: 72px;
            }
        }

        .container-sections {
            display: grid;
            grid-template-columns: 1fr;
            max-width: 421px;

            .section-1 {
                width: 100%;
                max-width: 421px;
                height: 165px;
                font-size: 48px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    margin-top: 14px;
                    z-index: 1;
                }
            }

            .section-2 {
                background-color: #fff;
                min-height: fit-content;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 60px 50px 30px 50px;
                max-width: 421px;

                h2 {
                    font-size: 30px;
                    color: #004a34;
                    margin-top: 2px;
                    margin-bottom: 2px;
                    font-family: 'FontRegular';
                    text-transform: capitalize;
                }

                p {
                    font-size: 18px;
                }

                button {
                    max-width: 321px;
                }
            }
        }
    }
}
</style>
  