<template>
  <el-container id="payment-form" class="payment-form">
    <div class="content">
      <img :src="`${this.baseURL}/assets/jameson.svg`"  class="logo_jameson">
      <div class="content-payment">
        <div class="checkout_form">
          <checkout-form
            v-if="state === 1"
            :cart="cart"
            :disable="disableForm"
            v-on:payment="handlePayment"
            v-on:zipUpdated="handleBillingZipcodeChange"
          ></checkout-form>
          <checkout-thankyou
            v-else
            :cart="cart"
            v-on:thanks="handleThanks"
            :orderInfo="orderInfo"
          ></checkout-thankyou>
        </div>
        <div class="checkout_cart">
          <checkout-cart :cart="cart" :taxinfo="taxInfo"></checkout-cart>
        </div>
      </div>
    </div>
  </el-container>
</template>

<script>
import CheckoutForm from "@/components/CheckoutForm.vue";
import CheckoutThankyou from "@/components/CheckoutThankyou.vue";
import CheckoutCart from "@/components/CheckoutCart.vue";
import axios from 'axios';
import { mapGetters } from 'vuex';
import Util from "@/lib/Util";

export default {
  props: {

  },
  components: {
    CheckoutForm,
    CheckoutCart,
    CheckoutThankyou,
  },
  data() {
    return {
      baseURL: process.env.VUE_APP_URL_BASE ?? "",
      cart: [],
      state: 1,
      productId: null,
      product: null,
      taxInfo: null,
      updatingTaxInfo: false,
      updatingProductInfo: false,
      orderInfo: null
    };
  },
  computed: {
    ...mapGetters(['getUrlImage', 'getDecoy', 'getInstructions', 'getSize', 'getItemSelected']),
    disableForm() {
      const { updatingTaxInfo, updatingProductInfo } = this;
      return updatingTaxInfo || updatingProductInfo;
    }
  },
  methods: {
    handleThanks() {

    },
    handlePayment(orderInfo) {
      this.state = 2;
      this.orderInfo = orderInfo;
    },
    async handleBillingZipcodeChange(zip) {
      this.taxInfo = await this.getTaxRate(zip);
    },
    async getProductInfo(id) {
      try {
        if (!id) {
          return;
        }

        const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
        const url = `${VUE_APP_API_BASE}/products/${id}`;
        const config = {
          headers: {
            "Authorization": `Bearer ${VUE_APP_API_KEY}`,
          }
        };
        const { data } = await axios.get(url, config);
        return data?.product ?? null;

      } catch (error) {
        console.error(error);
      }
    },

    async getTaxRate(zip) {
      try {
        this.updatingProductInfo = true;
        if (!zip) {
          throw("Missing Zipcode");
          // return;
        }

        const isValidUSZip = (sZip) => {
          return /^\d{5}(-\d{4})?$/.test(sZip);
        }

        if (!isValidUSZip) {
          throw("Invalid US Zipcode");
          // return;
        }

        const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
        const url = `${VUE_APP_API_BASE}/tax/${zip}`;
        const config = {
          headers: {
            "Authorization": `Bearer ${VUE_APP_API_KEY}`,
          }
        };
        const { data } = await axios.get(url, config);
        return data ?? null;

      } catch (error) {
        console.error(error);
      } finally {
        this.updatingProductInfo = false;
      }
    },
  },
  async mounted() {
   Util.scrollTo();
    try {
      this.updatingProductInfo = true;
      this.product = await this.getProductInfo(this.getItemSelected);
      this.productId = this.getItemSelected;
    } catch (e) {
      console.error(e);
    } finally {
      this.updatingProductInfo = false;
    }
    const newCartObj = {
      "product_id": this.getItemSelected,
      "quantity": 1,
      "fields": {
        "img_face": this.getUrlImage,
        "img_body": this.getDecoy,
        "txt_instructions": this.getInstructions,
        "txt_decoy_size": this.getSize,
      },
      productInfo: this.product,
    };

    this.cart.push(newCartObj);
    // this.$set(this.cart, 0, cartProduct);

  }
};
</script>

<style lang="scss" scoped>

.checkout_cart {
  background: #F2FFF4;
  height: 873px;
  width: 360px;
  @media only screen and (min-width: 1250px) {
    width: 470px;
  }
  @media only screen and (max-width: 768px) {
    height: auto;
    width: auto;
  }

  .logo_jameson{
    max-width: 196px;
    margin-bottom: 41px;
  }
}

.payment-form {
  @media only screen and (max-width: 768px) {
    display: flex;
  }

  .content {
    img {
      display: none;
    }

    @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      img {
        display: block;
        height: 110px;
        padding: 30px 0 30px 0;
      }
    }

  }
}

.content-payment {
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
}

.checkout_form {
  @media only screen and (min-width: 769px) {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 15px;
    width: 70%;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
}
</style>