<template>
  <div v-if="product" class="cart_product">
    <div>
      <div class="img_product">
        <img :src="`${this.baseURL}/assets/init/jacket.png`" />
        <span class="counter">1</span>
      </div>
    </div>

    <div class="product_text">
      <p>
        <!-- Jameson
        <br />
        Desk Decoy -->
        {{ product?.productInfo?.name }}
      </p>
    </div>

    <div class="product_price">
      <p>${{ parseFloat(product?.productInfo?.price ?? 0).toFixed(2) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product"],
  data() {
    return {
      baseURL: process.env.VUE_APP_URL_BASE ?? "",
    };
  },
  methods: {},
  mounted() {},
  beforeUnmount() {},
};
</script>

<style lang="scss" scoped>

.counter {
  background: #004A34;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  color: #FFF;
  font-size: 12.5px;
  padding: 5px;
  position: absolute;
  top: -15px;
  right: -15px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart_product {
  display: flex;
}

.product_text,
.product_price {
  display: grid;
  align-items: end;
  text-align: initial;
  min-width: 110px;

  p {
    padding: 0 10px;
    margin: 0;
  }
}

.product_text {
  p {
    font-family: "JJRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.03em;
    color: #293644;
  }
}

.product_price {
  p {
    font-family: "JJRegular";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #293644;
  }
}

.img_product {
  position: relative;
  background: #ffffff;
  border: 1px solid #d9d3d3;
  border-radius: 4px;
  width: 94px;
  height: 91.42px;

  img {
    padding: 10px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
</style>
