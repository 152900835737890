import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
});

export default new Vuex.Store({
  state: () => ({
    authExternal: null,
    decoy: null,
    fingerprint :null,
    instructions: "",
    itemSelected: null,
    orderToEdit: null,
    refreshToken: null,
    size: "",
    token: null,
    urlImage: "",
    user: null,
    stockSoldOut: false, 
  }),
  getters: {
    isLoggedIn(state) {
      return !!state.token;
    },
    isRefreshToken(state) {
      return !!state.refreshToken;
    },
    isAuthExternal(state) {
      return !!state.authExternal;
    },
    getUrlImage(state){
      return state.urlImage;
    },
    getDecoy(state){
      return state.decoy;
    },
    getInstructions(state){
      return state.instructions;
    },
    getSize(state){
      return state.size;
    },
    getFingerprint(state){
      return state.fingerprint;
    },
    getItemSelected(state){
      return state.itemSelected;
    },
    getStockSoldOut(state){
      return state.stockSoldOut;
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
    setRefreshToken(state, refreshToken) {
      state.refreshToken = refreshToken;
    },
    setAuthExternal(state, authExternal) {
      state.authExternal = authExternal;
    },
    setUrlImage(state, urlImage) {
      state.urlImage = urlImage;
    },
    setDecoy(state, decoy) {
      state.decoy = decoy;
    },
    setInstructions(state, instructions) {
      state.instructions = instructions;
    },
    setSize(state, size) {
      state.size = size;
    },
    setOrderToEdit(state, order) {
      state.orderToEdit = order;
      state.urlImage = order?.detail[0]?.fields.img_face ?? null;
      state.decoy = order?.detail[0]?.fields.img_body ?? null;
      state.size = order?.detail[0]?.fields.txt_decoy_size ?? null;
      state.instructions = order?.detail[0]?.fields.txt_instructions ?? null;
      // eslint-disable-next-line no-debugger
      // debugger;
    },
    setFingerprint(state, fingerprint){
      state.fingerprint = fingerprint;
    },
    setItemSelected(state, itemSelected){
      state.itemSelected = itemSelected;
    },
    setStockSoldOut(state, stockSoldOut){
      state.stockSoldOut = stockSoldOut;
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin],
});
