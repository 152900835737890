import Vue from 'vue';
// import VueJWT from 'vuejs-jwt';
import vueCustomElement from 'vue-custom-element';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import lang from 'element-ui/lib/locale/lang/en';
import locale from 'element-ui/lib/locale';
import VueTour from 'vue-tour';
import store from './store';
import router from './router';
import App from './App.vue';
import VueMask from 'v-mask'
import Vue2TouchEvents from 'vue2-touch-events'
import VueGtag from "vue-gtag";
import uuid  from "vue-uuid";

import axios from 'axios';

// Set Axios defaults
axios.defaults.withCredentials = true;

Vue.config.productionTip = false;

// configure language
Vue.use(ElementUI);
Vue.use(uuid);

const gaTrackingCodes = [
  { id: process.env.VUE_APP_GA },
];
const clientGA = process.env.VUE_APP_CLIENT_GA;
if (clientGA != null && clientGA.length > 2) {
  gaTrackingCodes.push({ id: clientGA });
}
if (process.env.VUE_APP_GA) {
  Vue.use(VueGtag, {
    // config: { id: process.env.VUE_APP_GA },
    includes: gaTrackingCodes,
    params: {
      send_page_view: false,
    },
  });
}

// console.log('environment:::', process.env.VUE_APP_CURRENT_ENV);
// console.log('GA:::', process.env.VUE_APP_GA);
// console.log('GA CLIENT:::', process.env.VUE_APP_CLIENT_GA);

Vue.use(VueMask);
Vue.use(Vue2TouchEvents);
locale.use(lang);
require('vue-tour/dist/vue-tour.css');

Vue.use(VueTour);
// Vue.use(VueJWT);
Vue.use(vueCustomElement);

App.store = store;
App.router = router;

Vue.customElement('jameson-decoy', App, {
  shadow: false,
});

// console.log('ENV VARS:\n====');
// console.log(process.env);