<template>
    <div class="debrain-create-jameson">
        <div class="jacket-image">
            <img :src="`${baseURL}/assets/init/principal_image.jpg`" alt="jacket_img"/>
        </div>

        <div class="content-elements">
            <h1 class="title">St. Patrick’s Eve, in style</h1>
            <p class="description" v-if="stock > 0">
                Every special holiday deserves an equally special look.
                We created 200 limited-edition <b>St. Patrick’s Eve jackets</b>,
                perfect for the occasion. <span class="break-mobile"><br></span> We’ll be dropping 50 per week
                until March 7th. Don’t miss out—once they’re gone,
                they’re gone for good.
            </p>
            <!-- <p v-else-if="stock <= 0 && soldOutLastWeek" class="description">
                This week’s jacket drop is already sold out. But check back! We’ll release 50 more next Tuesday at 9
                a.m. ET.
            </p> -->
            <p v-else class="description">
                Sorry about that! We still have plenty of exciting things. Visit us here at JamesonSPE.com and watch
                the Times Square ‘rock drop’ live at 8:00 p.m. ET (aka midnight in Ireland).
            </p>
            <!-- <button
                class="green-btn"
                v-if="stock <= 0"
                disabled
                @click="showModal = false"
            >
                COME BACK LATER
            </button> -->
            <button
                v-if="stock > 0"
                class="green-btn"
                :disabled="disableButton"
                @click="beginOrderFlow()"
            >
                {{ buttonText }}
            </button>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
import { mapMutations } from "vuex";

export default {
    name: "CreateYourJameson",
    data: () => ({
        baseURL: process.env.VUE_APP_URL_BASE ?? "",
        loading: true,
        soldOut: false,
        error: false,
        errorMessage: null,
        messageAvailability: false,
        stock: 0,
        showModal: false,
    }),
    computed: {
        buttonText() {
            const { loading, soldOut, errorMessage } = this;
            if (loading) {
                return "LOADING...";
            }
            if (soldOut) {
                return "SOLD OUT";
            }
            if (typeof errorMessage === "string" && errorMessage.length > 0) {
                return errorMessage;
            }
            return "BUY YOURS";
        },
        disableButton() {
            const { loading, soldOut, error, errorMessage } = this;
            if (loading) {
                return true;
            }
            if (soldOut) {
                return true;
            }
            if (error || errorMessage != null) {
                return true;
            }
            return false;
        },
        soldOutLastWeek() {
            const today = Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate());
            const lastWeek = Date.UTC(2024, 2, 12);
            // console.log(today, lastWeek, today <= lastWeek);
            return today <= lastWeek;
        },
    },
    methods: {
        ...mapMutations([
            "setOrderToEdit",
            "setUrlImage",
            "setDecoy",
            "setInstructions",
            "setSize",
            "setStockSoldOut"
        ]),

        beginOrderFlow() {
            this.$gtag.event("Click", {
                event_category: "Button Click",
                event_label: "Create & Order Yours",
                value: "",
            });
            this.$router.push({ name: "SelectProduct" });
        },
        async getProductsStock() {
            try {
                const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
                const url = `${VUE_APP_API_BASE}/products/stock`;

                const config = {
                    headers: {
                        Authorization: `Bearer ${VUE_APP_API_KEY}`,
                    },
                    withCredentials: true,
                };

                const { data } = await axios.get(url, config);

                const { available_stock } = data;

                return available_stock ?? 0;
            } catch (error) {
                console.error(error);
                /*
                 Swal.fire({
                 target: document.getElementById("app"),
                 icon: "error",
                 title: "Notice",
                 text: "Failed to get product availability",
                 });
                 */
                return null;
            }
        },
        async getOrderInfo(order_id) {
            try {
                const { VUE_APP_API_BASE, VUE_APP_API_KEY } = process.env;
                const url = `${VUE_APP_API_BASE}/orders/${order_id}`;

                const config = {
                    headers: {
                        Authorization: `Bearer ${VUE_APP_API_KEY}`,
                    },
                };

                const { data } = await axios.get(url, config);
                return data.order;
            } catch (error) {
                console.error(error);
                Swal.fire({
                    target: document.getElementById("app"),
                    icon: "error",
                    title: "Notice",
                    text: "Failed to get Order",
                });
                return null;
            }
        },
        getParameterByName(name, url = window.location.href) {
            name = name.replace(/[[\]]/g, "\\$&");
            var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },
        getAvailability(stock) {
            try {
                let available = null;
                // const { stock } = this;
                if (!stock) {
                    // return available;

                    throw Object.assign(
                        new Error(
                            "Failed to get stock availability, please check back later"
                        ),
                        { code: 50001 }
                    );
                }

                const available_stock = stock;
                console.log("%Stock available: " + available_stock, "color:orange;");

                if (+available_stock <= 0) {
                    throw Object.assign(
                        new Error("This products is not available at the time."),
                        { code: 100111 }
                    );
                }

                available = +available_stock;
                if (available <= 0) {
                    throw Object.assign(
                        new Error(
                            "This products is not available at the time. Plese check back later"
                        ),
                        { code: 1002 }
                    );
                } else {
                    this.disableNavigation = false;
                    this.disableNavigationMessage = null;
                }

                return [available, true, null];
            } catch (error) {
                const message =
                    error?.message ?? "Not enough availability at this moment";
                const code = error?.code ?? 500;
                return [0, false, message, code];
            }
        },
    },

    async mounted() {
        try {
            try {
                this.$gtag.pageview("/");
            } catch (error) {
                console.warn("Page view was not tracked");
            }
            this.loading = true;
            this.error = false;
            this.stock = await this.getProductsStock();

            // Check if process is reupload
            const orderIDParam = this.getParameterByName("decoyorder") ?? null;
            let order = null;
            if (orderIDParam) {
                // eslint-disable-next-line no-debugger
                // debugger;
                order = await this.getOrderInfo(orderIDParam);
                if (order) {
                    if (+order.allow_reupload === 0) {
                        Swal.fire({
                            target: document.getElementById("app"),
                            icon: "warning",
                            title: "Notice",
                            text: "Link to update order is expired",
                        });
                        this.setUrlImage(null);
                        this.setDecoy(null);
                        this.setInstructions(null);
                        this.setSize(null);
                        this.setOrderToEdit(null);
                        this.setStockSoldOut(false);
                        // return;
                    } else {
                        this.order = order;
                        this.setOrderToEdit(order);
                        this.beginOrderFlow();
                        // return;
                    }
                } else {
                    console.error("Invalid order id, continuing default process");
                }
            } else {
                this.setUrlImage(null);
                this.setDecoy(null);
                this.setInstructions(null);
                this.setSize(null);
                this.setOrderToEdit(null);
                this.setStockSoldOut(false);
            }

            // Check stock availability
            const [
                available,
                getAvailabilityStatus,
                getAvailabilityError,
                getAvailabilityErrorCode,
            ] = this.getAvailability(this.stock);

            this.available = available;

            if (!getAvailabilityStatus || getAvailabilityError != null) {
                throw Object.assign(new Error(getAvailabilityError), {
                    code: getAvailabilityErrorCode ?? 500,
                });
            }

            // if (orderIDParam) {
            //     this.$router.push({ name: "Home" });
            // }
        } catch (error) {
            console.error(error);
            // const message = (error.message) ?? "Not currently available";
            this.showModal = true;
            this.setStockSoldOut(true);
            const message = "come back later";
            this.error = true;
            this.errorMessage = message;
            const code = error?.code ?? 500;

            if (error.message != null && !(code === 1001 || code === 1002)) {
                if (error.code !== 50001 || error.code !== 100111) {
                    this.messageAvailability = true;
                    return;
                }
                Swal.fire({
                    target: document.getElementById("app"),
                    icon: "error",
                    // title: "Notice",
                    text: error.message,
                });
            }
        } finally {
            this.loading = false;
        }
    },
};
</script>
<style lang="scss">
.debrain-create-jameson {
    background: #FEF8E1 !important;
    min-height: 70dvh;

    .init-modal {
        .el-dialog {
            width: 100%;
            max-width: 472px;

            @media (max-width: 768px) {
                width: 90%;
            }
        }

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            padding: 0 !important;
        }

        .section-title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px;

            img {
                margin-top: -85px;
                width: auto;
                height: auto;
                display: block;
            }
        }

        .bg-image {
            position: relative;

            img {
                max-width: 100%;
            }

            .btn-close {
                position: absolute;
                right: 0;
                top: 0;
                padding: 15px;
                cursor: pointer;
                color: #ffffff;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .container {
            padding: 50px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title {
                color: #004a34;
                font-family: JJLt-Regular;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 57.6px */
            }

            .description {
                color: #000;
                font-family: JJLt-Regular;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                /* 26.1px */
            }

            .green-btn {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                outline: none;
                border: none;
                text-transform: uppercase;
                font-family: FontRegular;
                transition: all 125ms;
                /* identical to box height, or 144% */

                letter-spacing: 2px;

                color: #ffffff;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }

                &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }
    }

    .init-modal-inventory {
        .el-dialog {
            width: 100%;
            max-width: 800px;

            @media (max-width: 768px) {
                width: 90%;
            }
        }

        .el-dialog__header {
            display: none;
        }

        .el-dialog__body {
            padding: 0 !important;
        }

        .section-title {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 30px;

            img {
                margin-top: -85px;
                width: auto;
                height: auto;
                display: block;

            }
        }

        .bg-image {
            position: relative;

            img {
                max-width: 100%;
            }

            .btn-close {
                position: absolute;
                right: 0;
                top: 0;
                padding: 15px;
                cursor: pointer;
                color: #ffffff;

                &:hover {
                    opacity: 0.5;
                }
            }
        }

        .container {
            padding: 50px 100px;
            display: flex;
            flex-direction: column;
            align-items: center;

            @media (max-width: 767px) {
                padding: 50px;
            }

            .title {
                color: #004a34;
                font-family: JJRegular;
                font-size: 48px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                margin-bottom: 20px;
                /* 57.6px */

                @media (max-width: 767px) {
                    font-size: 32px;
                    text-align: center;
                }
            }

            .description {
                color: #000;
                font-family: JJRegular;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 145%;
                margin: 0 0 20px 0;
                max-width: 530px;
                /* 26.1px */
            }

            .green-btn {
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                outline: none;
                border: none;
                text-transform: uppercase;
                font-family: FontRegular;
                transition: all 125ms;
                max-width: 100%;
                /* identical to box height, or 144% */

                letter-spacing: 2px;

                color: #ffffff;
                cursor: pointer;

                &:hover {
                    opacity: 0.6;
                    cursor: pointer;
                }

                &:disabled {
                    opacity: 0.6;
                    cursor: not-allowed;
                }
            }
        }
    }

    .jacket-image {
        img {
            flex-shrink: 0;
            max-width: 580px;
            display: block;
            margin-left: auto;
            margin-right: 20px;

            @media (max-width: 720px) {
                width: 100dvw;
                height: auto;
                max-width: 500px;
                margin-right: 0;
            }
        }
    }

    .content-elements {
        text-align: start;
        max-width: 461px;

        margin-left: calc(4.375rem + 40px);

        @media (max-width: 767px) {
            margin-left: 0;
        }

        @media (max-width: 720px) {
            text-align: center;
            padding: 0 20px;
            margin-bottom: 60px;
        }

        .title {
            color: #004a34;
            font-family: JJRegular;
            font-size: 48px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            max-width: 435px;
            // padding: 0 10px;
            margin-bottom: 20px;

            @media (min-width: 1001px) and (max-width: 1200px) {
                font-size: 43px;
            }

            @media (max-width: 767px) {
                font-size: 33px;
                padding: 0 45px;
            }
            /* 57.6px */
        }

        .description {
            color: #070707;
            font-family: JJRegular;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 145%;
            margin-bottom: 40px;
            // padding: 0 10px;
            @media (max-width: 720px) {
                padding: 0 10px;
            }
            /* 26.1px */
        }

        .green-btn {
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            outline: none;
            border: none;
            text-transform: uppercase;
            font-family: FontRegular;
            transition: all 125ms;
            padding: 0;
            
            @media (max-width: 767px) {
                margin: auto;
            }
            /* identical to box height, or 144% */

            letter-spacing: 2px;

            color: #ffffff;
            cursor: pointer;

            &:hover {
                opacity: 0.6;
                cursor: pointer;
            }

            &:disabled {
                opacity: 0.6;
                cursor: not-allowed;
            }
        }
    }

    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    padding: 90px;
    background-color: #F2FFF4;
    // gap: 85px;
    justify-content: center;

    @media (max-width: 1000px) {
        flex-direction: column;
        gap: 48px;
        padding: 54px;
    }

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding: 0;
        justify-content: start;
    }
}

.d-desktop {
    display: none;

    @media (min-width: 720px) {
        display: block;
    }
}

.d-mobile {
    display: block;

    @media (min-width: 720px) {
        display: none;
    }
}

.debrain-create-jameson .content-elements .description {
    padding-right: 60px;
    @media (min-width: 668px) and (max-width: 720px) {
        padding: 0 !important;
        font-size: 20px !important;
    }
    @media (min-width: 0) and (max-width: 667px) {
        font-size: 14px;
        padding: 0 !important;
    }
}

</style>
