<template class="token">
  <div :loading="loading" class="token-screen">
    <h1>this is the token page</h1>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
// import SSOApi from 'sso-api-integration';

export default {
  name: 'AuthToken',
  props: ['params'],
  components: {
  },
  data() {
    return {
      loading: false,
    };
  },
  watch: {
  },
  computed: {
  },
  methods: { 
    ...mapMutations(['setUser', 'setToken', 'setRefreshToken']),
    async validateToken() {
      // try {
      //   this.loading = true;
       
      //   const {data, success} = await SSOApi.exchangeUserToken(this.params.token);

      //   if(success) {
      //     const { accessToken, refreshToken } = data;
      //     const user = this.$jwt.decode(accessToken).data;
  
      //     this.setUser(user);
      //     this.setToken(accessToken);
      //     this.setRefreshToken(refreshToken);
  
      //     const state = atob(this.params.state);
  
      //     this.$router.push({ path: state });
      //   }


      // } catch (e) {
      //   console.error(e);
      //   this.loading = false;
      // } finally {
      //   this.loading = false;
      // }
    },
  },
  async mounted() {
    await this.validateToken();
  },
};
</script>
